import Vue from 'vue';
import Rollbar from 'vue-rollbar';
import Long from 'long';
import { Configuration } from 'rollbar';
import version from '~/misc/version';

const client = {
  javascript: {
    source_map_enabled: true,
    code_version: version.hash,
    // Optionally have Rollbar guess which frames the error was thrown from
    // when the browser does not provide line and column numbers.
    guess_uncaught_frames: true
  }
};

const configuration: Configuration = {
  accessToken: process.env.rollbarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: typeof process.env.rollbarAccessToken === 'string', // アクセストークンが格納されている場合のみ有効
  environment: process.env.NODE_ENV,
  payload: {
    client
  }
};

Vue.use(Rollbar, configuration);

/** グローバル経由でvuexにアクセスし、providerIdを取得する */
function getProviderIdFromGlobal(): string | null {
  const providerId = (window as any)?.$nuxt?.$store?.state?.provider?.poviderProfile?.id as Long | undefined;
  return providerId !== undefined ? new Long(providerId.low, providerId.high).toString() : null;
}

/** payloadにproviderIdを積むため、rollbarを更新する */
function configureRollbar(): void {
  (Vue as any).prototype.$rollbar.configure({
    payload: {
      client,
      person: { id: getProviderIdFromGlobal() }
    }
  });
}

Vue.config.errorHandler = (err, _vm, _info): void => {
  // Vueのスコープでエラーが発生した場合はこの関数によりハンドリングされる
  console.error(err.message);
  configureRollbar();
  (Vue as any).rollbar.error(err);
};

window.onerror = (_message, _source, _lineno, _colno, error): void => {
  // Vueがハンドリングできない範囲でエラーが発生した場合はこの関数によりハンドリングされる
  configureRollbar();
  (Vue as any).rollbar.error(error);
};
