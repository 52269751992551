/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const provider_api = $root.provider_api = (() => {

    const provider_api = {};

    provider_api.common = (function() {

        const common = {};

        common.StatusCode = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SUCCESS"] = 0;
            values[valuesById[1000] = "EXPIRED_TOKEN"] = 1000;
            values[valuesById[1001] = "INVALID_TOKEN"] = 1001;
            values[valuesById[1002] = "LOGIN_FAILED"] = 1002;
            values[valuesById[1003] = "LOGIN_FAILED_MULTIPLE_TIMES"] = 1003;
            values[valuesById[1004] = "WRONG_PASSWORD"] = 1004;
            values[valuesById[1005] = "INVALID_PASSWORD"] = 1005;
            values[valuesById[1006] = "INVALID_PARAMS"] = 1006;
            values[valuesById[1500] = "INTERNAL_SERVER_ERROR"] = 1500;
            values[valuesById[1501] = "MAINTENANCE"] = 1501;
            return values;
        })();

        common.Status = (function() {

            function Status(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Status.prototype.code = 0;
            Status.prototype.message = "";
            Status.prototype.redirectUrl = "";

            Status.create = function create(properties) {
                return new Status(properties);
            };

            Status.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.code != null && m.hasOwnProperty("code"))
                    w.uint32(8).int32(m.code);
                if (m.message != null && m.hasOwnProperty("message"))
                    w.uint32(18).string(m.message);
                if (m.redirectUrl != null && m.hasOwnProperty("redirectUrl"))
                    w.uint32(26).string(m.redirectUrl);
                return w;
            };

            Status.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.common.Status();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.code = r.int32();
                        break;
                    case 2:
                        m.message = r.string();
                        break;
                    case 3:
                        m.redirectUrl = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return Status;
        })();

        return common;
    })();

    provider_api.payment = (function() {

        const payment = {};

        payment.histories = (function() {

            const histories = {};

            histories.Service = (function() {

                function Service(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Service.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Service;

                Service.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };


                Object.defineProperty(Service.prototype.paymentHistories = function paymentHistories(request, callback) {
                    return this.rpcCall(paymentHistories, $root.provider_api.payment.histories.Request, $root.provider_api.payment.histories.Response, request, callback);
                }, "name", { value: "PaymentHistories" });

                return Service;
            })();

            histories.Request = (function() {

                function Request(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Request.prototype.year = 0;

                Request.create = function create(properties) {
                    return new Request(properties);
                };

                Request.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.year != null && m.hasOwnProperty("year"))
                        w.uint32(8).uint32(m.year);
                    return w;
                };

                Request.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.payment.histories.Request();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.year = r.uint32();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Request;
            })();

            histories.Response = (function() {

                function Response(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Response.prototype.status = null;
                Response.prototype.data = null;

                Response.create = function create(properties) {
                    return new Response(properties);
                };

                Response.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && m.hasOwnProperty("status"))
                        $root.provider_api.common.Status.encode(m.status, w.uint32(10).fork()).ldelim();
                    if (m.data != null && m.hasOwnProperty("data"))
                        $root.provider_api.payment.histories.Response.Data.encode(m.data, w.uint32(18).fork()).ldelim();
                    return w;
                };

                Response.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.payment.histories.Response();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.status = $root.provider_api.common.Status.decode(r, r.uint32());
                            break;
                        case 2:
                            m.data = $root.provider_api.payment.histories.Response.Data.decode(r, r.uint32());
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                Response.Data = (function() {

                    function Data(p) {
                        this.dailyHistories = [];
                        this.monthlyHistories = [];
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    Data.prototype.dailyHistories = $util.emptyArray;
                    Data.prototype.monthlyHistories = $util.emptyArray;

                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    Data.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.dailyHistories != null && m.dailyHistories.length) {
                            for (var i = 0; i < m.dailyHistories.length; ++i)
                                $root.provider_api.payment.histories.Response.PaymentHistory.encode(m.dailyHistories[i], w.uint32(10).fork()).ldelim();
                        }
                        if (m.monthlyHistories != null && m.monthlyHistories.length) {
                            for (var i = 0; i < m.monthlyHistories.length; ++i)
                                $root.provider_api.payment.histories.Response.PaymentHistory.encode(m.monthlyHistories[i], w.uint32(18).fork()).ldelim();
                        }
                        return w;
                    };

                    Data.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.payment.histories.Response.Data();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1:
                                if (!(m.dailyHistories && m.dailyHistories.length))
                                    m.dailyHistories = [];
                                m.dailyHistories.push($root.provider_api.payment.histories.Response.PaymentHistory.decode(r, r.uint32()));
                                break;
                            case 2:
                                if (!(m.monthlyHistories && m.monthlyHistories.length))
                                    m.monthlyHistories = [];
                                m.monthlyHistories.push($root.provider_api.payment.histories.Response.PaymentHistory.decode(r, r.uint32()));
                                break;
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    return Data;
                })();

                Response.PaymentHistory = (function() {

                    function PaymentHistory(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    PaymentHistory.prototype.date = "";
                    PaymentHistory.prototype.amount = 0;

                    PaymentHistory.create = function create(properties) {
                        return new PaymentHistory(properties);
                    };

                    PaymentHistory.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.date != null && m.hasOwnProperty("date"))
                            w.uint32(10).string(m.date);
                        if (m.amount != null && m.hasOwnProperty("amount"))
                            w.uint32(16).uint32(m.amount);
                        return w;
                    };

                    PaymentHistory.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.payment.histories.Response.PaymentHistory();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1:
                                m.date = r.string();
                                break;
                            case 2:
                                m.amount = r.uint32();
                                break;
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    return PaymentHistory;
                })();

                return Response;
            })();

            return histories;
        })();

        return payment;
    })();

    provider_api.profile = (function() {

        const profile = {};

        profile.fetch = (function() {

            const fetch = {};

            fetch.Service = (function() {

                function Service(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Service.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Service;

                Service.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };


                Object.defineProperty(Service.prototype.profileFetch = function profileFetch(request, callback) {
                    return this.rpcCall(profileFetch, $root.provider_api.profile.fetch.Request, $root.provider_api.profile.fetch.Response, request, callback);
                }, "name", { value: "ProfileFetch" });

                return Service;
            })();

            fetch.Request = (function() {

                function Request(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Request.create = function create(properties) {
                    return new Request(properties);
                };

                Request.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    return w;
                };

                Request.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.profile.fetch.Request();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Request;
            })();

            fetch.Response = (function() {

                function Response(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Response.prototype.status = null;
                Response.prototype.data = null;

                Response.create = function create(properties) {
                    return new Response(properties);
                };

                Response.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && m.hasOwnProperty("status"))
                        $root.provider_api.common.Status.encode(m.status, w.uint32(10).fork()).ldelim();
                    if (m.data != null && m.hasOwnProperty("data"))
                        $root.provider_api.profile.fetch.Response.Data.encode(m.data, w.uint32(18).fork()).ldelim();
                    return w;
                };

                Response.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.profile.fetch.Response();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.status = $root.provider_api.common.Status.decode(r, r.uint32());
                            break;
                        case 2:
                            m.data = $root.provider_api.profile.fetch.Response.Data.decode(r, r.uint32());
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                Response.Data = (function() {

                    function Data(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    Data.prototype.providerId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
                    Data.prototype.email = "";

                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    Data.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.providerId != null && m.hasOwnProperty("providerId"))
                            w.uint32(8).uint64(m.providerId);
                        if (m.email != null && m.hasOwnProperty("email"))
                            w.uint32(18).string(m.email);
                        return w;
                    };

                    Data.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.profile.fetch.Response.Data();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1:
                                m.providerId = r.uint64();
                                break;
                            case 2:
                                m.email = r.string();
                                break;
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    return Data;
                })();

                return Response;
            })();

            return fetch;
        })();

        return profile;
    })();

    provider_api.provider = (function() {

        const provider = {};

        provider.change_password = (function() {

            const change_password = {};

            change_password.Service = (function() {

                function Service(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Service.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Service;

                Service.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };


                Object.defineProperty(Service.prototype.providerChangePassword = function providerChangePassword(request, callback) {
                    return this.rpcCall(providerChangePassword, $root.provider_api.provider.change_password.Request, $root.provider_api.provider.change_password.Response, request, callback);
                }, "name", { value: "ProviderChangePassword" });

                return Service;
            })();

            change_password.Request = (function() {

                function Request(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Request.prototype.currentPassword = "";
                Request.prototype.newPassword = "";

                Request.create = function create(properties) {
                    return new Request(properties);
                };

                Request.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.currentPassword != null && m.hasOwnProperty("currentPassword"))
                        w.uint32(10).string(m.currentPassword);
                    if (m.newPassword != null && m.hasOwnProperty("newPassword"))
                        w.uint32(18).string(m.newPassword);
                    return w;
                };

                Request.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.provider.change_password.Request();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.currentPassword = r.string();
                            break;
                        case 2:
                            m.newPassword = r.string();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Request;
            })();

            change_password.Response = (function() {

                function Response(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Response.prototype.status = null;
                Response.prototype.data = null;

                Response.create = function create(properties) {
                    return new Response(properties);
                };

                Response.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && m.hasOwnProperty("status"))
                        $root.provider_api.common.Status.encode(m.status, w.uint32(10).fork()).ldelim();
                    if (m.data != null && m.hasOwnProperty("data"))
                        $root.provider_api.provider.change_password.Response.Data.encode(m.data, w.uint32(18).fork()).ldelim();
                    return w;
                };

                Response.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.provider.change_password.Response();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.status = $root.provider_api.common.Status.decode(r, r.uint32());
                            break;
                        case 2:
                            m.data = $root.provider_api.provider.change_password.Response.Data.decode(r, r.uint32());
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                Response.Data = (function() {

                    function Data(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    Data.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    Data.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.provider.change_password.Response.Data();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    return Data;
                })();

                return Response;
            })();

            return change_password;
        })();

        provider.login = (function() {

            const login = {};

            login.Service = (function() {

                function Service(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Service.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Service;

                Service.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };


                Object.defineProperty(Service.prototype.providerLogin = function providerLogin(request, callback) {
                    return this.rpcCall(providerLogin, $root.provider_api.provider.login.Request, $root.provider_api.provider.login.Response, request, callback);
                }, "name", { value: "ProviderLogin" });

                return Service;
            })();

            login.Request = (function() {

                function Request(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Request.prototype.email = "";
                Request.prototype.password = "";

                Request.create = function create(properties) {
                    return new Request(properties);
                };

                Request.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.email != null && m.hasOwnProperty("email"))
                        w.uint32(10).string(m.email);
                    if (m.password != null && m.hasOwnProperty("password"))
                        w.uint32(18).string(m.password);
                    return w;
                };

                Request.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.provider.login.Request();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.email = r.string();
                            break;
                        case 2:
                            m.password = r.string();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Request;
            })();

            login.Response = (function() {

                function Response(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Response.prototype.status = null;
                Response.prototype.data = null;

                Response.create = function create(properties) {
                    return new Response(properties);
                };

                Response.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && m.hasOwnProperty("status"))
                        $root.provider_api.common.Status.encode(m.status, w.uint32(10).fork()).ldelim();
                    if (m.data != null && m.hasOwnProperty("data"))
                        $root.provider_api.provider.login.Response.Data.encode(m.data, w.uint32(18).fork()).ldelim();
                    return w;
                };

                Response.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.provider.login.Response();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.status = $root.provider_api.common.Status.decode(r, r.uint32());
                            break;
                        case 2:
                            m.data = $root.provider_api.provider.login.Response.Data.decode(r, r.uint32());
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                Response.Data = (function() {

                    function Data(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    Data.prototype.providerId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    Data.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.providerId != null && m.hasOwnProperty("providerId"))
                            w.uint32(8).uint64(m.providerId);
                        return w;
                    };

                    Data.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.provider.login.Response.Data();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1:
                                m.providerId = r.uint64();
                                break;
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    return Data;
                })();

                return Response;
            })();

            return login;
        })();

        provider.logout = (function() {

            const logout = {};

            logout.Service = (function() {

                function Service(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Service.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Service;

                Service.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };


                Object.defineProperty(Service.prototype.providerLogout = function providerLogout(request, callback) {
                    return this.rpcCall(providerLogout, $root.provider_api.provider.logout.Request, $root.provider_api.provider.logout.Response, request, callback);
                }, "name", { value: "ProviderLogout" });

                return Service;
            })();

            logout.Request = (function() {

                function Request(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Request.create = function create(properties) {
                    return new Request(properties);
                };

                Request.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    return w;
                };

                Request.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.provider.logout.Request();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Request;
            })();

            logout.Response = (function() {

                function Response(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Response.prototype.status = null;
                Response.prototype.data = null;

                Response.create = function create(properties) {
                    return new Response(properties);
                };

                Response.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && m.hasOwnProperty("status"))
                        $root.provider_api.common.Status.encode(m.status, w.uint32(10).fork()).ldelim();
                    if (m.data != null && m.hasOwnProperty("data"))
                        $root.provider_api.provider.logout.Response.Data.encode(m.data, w.uint32(18).fork()).ldelim();
                    return w;
                };

                Response.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.provider.logout.Response();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.status = $root.provider_api.common.Status.decode(r, r.uint32());
                            break;
                        case 2:
                            m.data = $root.provider_api.provider.logout.Response.Data.decode(r, r.uint32());
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                Response.Data = (function() {

                    function Data(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    Data.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        return w;
                    };

                    Data.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.provider.logout.Response.Data();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    return Data;
                })();

                return Response;
            })();

            return logout;
        })();

        provider.now = (function() {

            const now = {};

            now.Service = (function() {

                function Service(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Service.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Service;

                Service.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };


                Object.defineProperty(Service.prototype.providerNow = function providerNow(request, callback) {
                    return this.rpcCall(providerNow, $root.provider_api.provider.now.Request, $root.provider_api.provider.now.Response, request, callback);
                }, "name", { value: "ProviderNow" });

                return Service;
            })();

            now.Request = (function() {

                function Request(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Request.create = function create(properties) {
                    return new Request(properties);
                };

                Request.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    return w;
                };

                Request.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.provider.now.Request();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Request;
            })();

            now.Response = (function() {

                function Response(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Response.prototype.status = null;
                Response.prototype.data = null;

                Response.create = function create(properties) {
                    return new Response(properties);
                };

                Response.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && m.hasOwnProperty("status"))
                        $root.provider_api.common.Status.encode(m.status, w.uint32(10).fork()).ldelim();
                    if (m.data != null && m.hasOwnProperty("data"))
                        $root.provider_api.provider.now.Response.Data.encode(m.data, w.uint32(18).fork()).ldelim();
                    return w;
                };

                Response.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.provider.now.Response();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.status = $root.provider_api.common.Status.decode(r, r.uint32());
                            break;
                        case 2:
                            m.data = $root.provider_api.provider.now.Response.Data.decode(r, r.uint32());
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                Response.Data = (function() {

                    function Data(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    Data.prototype.currentTime = "";

                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    Data.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.currentTime != null && m.hasOwnProperty("currentTime"))
                            w.uint32(10).string(m.currentTime);
                        return w;
                    };

                    Data.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.provider.now.Response.Data();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1:
                                m.currentTime = r.string();
                                break;
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    return Data;
                })();

                return Response;
            })();

            return now;
        })();

        return provider;
    })();

    provider_api.report = (function() {

        const report = {};

        report.cheer_by_athlete = (function() {

            const cheer_by_athlete = {};

            cheer_by_athlete.Service = (function() {

                function Service(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Service.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Service;

                Service.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };


                Object.defineProperty(Service.prototype.reportCheerByAthlete = function reportCheerByAthlete(request, callback) {
                    return this.rpcCall(reportCheerByAthlete, $root.provider_api.report.cheer_by_athlete.Request, $root.provider_api.report.cheer_by_athlete.Response, request, callback);
                }, "name", { value: "ReportCheerByAthlete" });

                return Service;
            })();

            cheer_by_athlete.Request = (function() {

                function Request(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Request.prototype.startDate = "";
                Request.prototype.endDate = "";

                Request.create = function create(properties) {
                    return new Request(properties);
                };

                Request.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.startDate != null && m.hasOwnProperty("startDate"))
                        w.uint32(10).string(m.startDate);
                    if (m.endDate != null && m.hasOwnProperty("endDate"))
                        w.uint32(18).string(m.endDate);
                    return w;
                };

                Request.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.report.cheer_by_athlete.Request();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.startDate = r.string();
                            break;
                        case 2:
                            m.endDate = r.string();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Request;
            })();

            cheer_by_athlete.Response = (function() {

                function Response(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Response.prototype.status = null;
                Response.prototype.data = null;

                Response.create = function create(properties) {
                    return new Response(properties);
                };

                Response.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && m.hasOwnProperty("status"))
                        $root.provider_api.common.Status.encode(m.status, w.uint32(10).fork()).ldelim();
                    if (m.data != null && m.hasOwnProperty("data"))
                        $root.provider_api.report.cheer_by_athlete.Response.Data.encode(m.data, w.uint32(18).fork()).ldelim();
                    return w;
                };

                Response.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.report.cheer_by_athlete.Response();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.status = $root.provider_api.common.Status.decode(r, r.uint32());
                            break;
                        case 2:
                            m.data = $root.provider_api.report.cheer_by_athlete.Response.Data.decode(r, r.uint32());
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                Response.Data = (function() {

                    function Data(p) {
                        this.cheerAmounts = [];
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    Data.prototype.cheerAmounts = $util.emptyArray;

                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    Data.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.cheerAmounts != null && m.cheerAmounts.length) {
                            for (var i = 0; i < m.cheerAmounts.length; ++i)
                                $root.provider_api.report.cheer_by_athlete.Response.AmountByAthlete.encode(m.cheerAmounts[i], w.uint32(10).fork()).ldelim();
                        }
                        return w;
                    };

                    Data.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.report.cheer_by_athlete.Response.Data();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1:
                                if (!(m.cheerAmounts && m.cheerAmounts.length))
                                    m.cheerAmounts = [];
                                m.cheerAmounts.push($root.provider_api.report.cheer_by_athlete.Response.AmountByAthlete.decode(r, r.uint32()));
                                break;
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    return Data;
                })();

                Response.AmountByAthlete = (function() {

                    function AmountByAthlete(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    AmountByAthlete.prototype.name = "";
                    AmountByAthlete.prototype.impression = 0;
                    AmountByAthlete.prototype.complete = 0;
                    AmountByAthlete.prototype.amount = 0;
                    AmountByAthlete.prototype.cvr = 0;
                    AmountByAthlete.prototype.ecpm = 0;
                    AmountByAthlete.prototype.processedEcpm = 0;

                    AmountByAthlete.create = function create(properties) {
                        return new AmountByAthlete(properties);
                    };

                    AmountByAthlete.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.name != null && m.hasOwnProperty("name"))
                            w.uint32(10).string(m.name);
                        if (m.impression != null && m.hasOwnProperty("impression"))
                            w.uint32(16).uint32(m.impression);
                        if (m.complete != null && m.hasOwnProperty("complete"))
                            w.uint32(24).uint32(m.complete);
                        if (m.amount != null && m.hasOwnProperty("amount"))
                            w.uint32(32).uint32(m.amount);
                        if (m.cvr != null && m.hasOwnProperty("cvr"))
                            w.uint32(41).double(m.cvr);
                        if (m.ecpm != null && m.hasOwnProperty("ecpm"))
                            w.uint32(49).double(m.ecpm);
                        if (m.processedEcpm != null && m.hasOwnProperty("processedEcpm"))
                            w.uint32(57).double(m.processedEcpm);
                        return w;
                    };

                    AmountByAthlete.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.report.cheer_by_athlete.Response.AmountByAthlete();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1:
                                m.name = r.string();
                                break;
                            case 2:
                                m.impression = r.uint32();
                                break;
                            case 3:
                                m.complete = r.uint32();
                                break;
                            case 4:
                                m.amount = r.uint32();
                                break;
                            case 5:
                                m.cvr = r.double();
                                break;
                            case 6:
                                m.ecpm = r.double();
                                break;
                            case 7:
                                m.processedEcpm = r.double();
                                break;
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    return AmountByAthlete;
                })();

                return Response;
            })();

            return cheer_by_athlete;
        })();

        report.cheer_by_date = (function() {

            const cheer_by_date = {};

            cheer_by_date.Service = (function() {

                function Service(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Service.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Service;

                Service.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };


                Object.defineProperty(Service.prototype.reportCheerByDate = function reportCheerByDate(request, callback) {
                    return this.rpcCall(reportCheerByDate, $root.provider_api.report.cheer_by_date.Request, $root.provider_api.report.cheer_by_date.Response, request, callback);
                }, "name", { value: "ReportCheerByDate" });

                return Service;
            })();

            cheer_by_date.Request = (function() {

                function Request(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Request.prototype.startDate = "";
                Request.prototype.endDate = "";

                Request.create = function create(properties) {
                    return new Request(properties);
                };

                Request.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.startDate != null && m.hasOwnProperty("startDate"))
                        w.uint32(10).string(m.startDate);
                    if (m.endDate != null && m.hasOwnProperty("endDate"))
                        w.uint32(18).string(m.endDate);
                    return w;
                };

                Request.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.report.cheer_by_date.Request();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.startDate = r.string();
                            break;
                        case 2:
                            m.endDate = r.string();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Request;
            })();

            cheer_by_date.Response = (function() {

                function Response(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Response.prototype.status = null;
                Response.prototype.data = null;

                Response.create = function create(properties) {
                    return new Response(properties);
                };

                Response.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && m.hasOwnProperty("status"))
                        $root.provider_api.common.Status.encode(m.status, w.uint32(10).fork()).ldelim();
                    if (m.data != null && m.hasOwnProperty("data"))
                        $root.provider_api.report.cheer_by_date.Response.Data.encode(m.data, w.uint32(18).fork()).ldelim();
                    return w;
                };

                Response.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.report.cheer_by_date.Response();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.status = $root.provider_api.common.Status.decode(r, r.uint32());
                            break;
                        case 2:
                            m.data = $root.provider_api.report.cheer_by_date.Response.Data.decode(r, r.uint32());
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                Response.Data = (function() {

                    function Data(p) {
                        this.cheerAmounts = [];
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    Data.prototype.cheerAmounts = $util.emptyArray;

                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    Data.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.cheerAmounts != null && m.cheerAmounts.length) {
                            for (var i = 0; i < m.cheerAmounts.length; ++i)
                                $root.provider_api.report.cheer_by_date.Response.AmountByDate.encode(m.cheerAmounts[i], w.uint32(10).fork()).ldelim();
                        }
                        return w;
                    };

                    Data.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.report.cheer_by_date.Response.Data();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1:
                                if (!(m.cheerAmounts && m.cheerAmounts.length))
                                    m.cheerAmounts = [];
                                m.cheerAmounts.push($root.provider_api.report.cheer_by_date.Response.AmountByDate.decode(r, r.uint32()));
                                break;
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    return Data;
                })();

                Response.AmountByDate = (function() {

                    function AmountByDate(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    AmountByDate.prototype.amount = 0;
                    AmountByDate.prototype.date = "";

                    AmountByDate.create = function create(properties) {
                        return new AmountByDate(properties);
                    };

                    AmountByDate.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.amount != null && m.hasOwnProperty("amount"))
                            w.uint32(8).uint32(m.amount);
                        if (m.date != null && m.hasOwnProperty("date"))
                            w.uint32(18).string(m.date);
                        return w;
                    };

                    AmountByDate.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.report.cheer_by_date.Response.AmountByDate();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1:
                                m.amount = r.uint32();
                                break;
                            case 2:
                                m.date = r.string();
                                break;
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    return AmountByDate;
                })();

                return Response;
            })();

            return cheer_by_date;
        })();

        report.cheer_by_url = (function() {

            const cheer_by_url = {};

            cheer_by_url.Service = (function() {

                function Service(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Service.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Service;

                Service.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };


                Object.defineProperty(Service.prototype.reportCheerByUrl = function reportCheerByUrl(request, callback) {
                    return this.rpcCall(reportCheerByUrl, $root.provider_api.report.cheer_by_url.Request, $root.provider_api.report.cheer_by_url.Response, request, callback);
                }, "name", { value: "ReportCheerByUrl" });

                return Service;
            })();

            cheer_by_url.Request = (function() {

                function Request(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Request.prototype.startDate = "";
                Request.prototype.endDate = "";

                Request.create = function create(properties) {
                    return new Request(properties);
                };

                Request.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.startDate != null && m.hasOwnProperty("startDate"))
                        w.uint32(10).string(m.startDate);
                    if (m.endDate != null && m.hasOwnProperty("endDate"))
                        w.uint32(18).string(m.endDate);
                    return w;
                };

                Request.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.report.cheer_by_url.Request();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.startDate = r.string();
                            break;
                        case 2:
                            m.endDate = r.string();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Request;
            })();

            cheer_by_url.Response = (function() {

                function Response(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Response.prototype.status = null;
                Response.prototype.data = null;

                Response.create = function create(properties) {
                    return new Response(properties);
                };

                Response.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && m.hasOwnProperty("status"))
                        $root.provider_api.common.Status.encode(m.status, w.uint32(10).fork()).ldelim();
                    if (m.data != null && m.hasOwnProperty("data"))
                        $root.provider_api.report.cheer_by_url.Response.Data.encode(m.data, w.uint32(18).fork()).ldelim();
                    return w;
                };

                Response.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.report.cheer_by_url.Response();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.status = $root.provider_api.common.Status.decode(r, r.uint32());
                            break;
                        case 2:
                            m.data = $root.provider_api.report.cheer_by_url.Response.Data.decode(r, r.uint32());
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                Response.Data = (function() {

                    function Data(p) {
                        this.cheerAmounts = [];
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    Data.prototype.cheerAmounts = $util.emptyArray;

                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    Data.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.cheerAmounts != null && m.cheerAmounts.length) {
                            for (var i = 0; i < m.cheerAmounts.length; ++i)
                                $root.provider_api.report.cheer_by_url.Response.AmountByUrl.encode(m.cheerAmounts[i], w.uint32(10).fork()).ldelim();
                        }
                        return w;
                    };

                    Data.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.report.cheer_by_url.Response.Data();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1:
                                if (!(m.cheerAmounts && m.cheerAmounts.length))
                                    m.cheerAmounts = [];
                                m.cheerAmounts.push($root.provider_api.report.cheer_by_url.Response.AmountByUrl.decode(r, r.uint32()));
                                break;
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    return Data;
                })();

                Response.AmountByUrl = (function() {

                    function AmountByUrl(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    AmountByUrl.prototype.amount = 0;
                    AmountByUrl.prototype.url = "";
                    AmountByUrl.prototype.title = "";

                    AmountByUrl.create = function create(properties) {
                        return new AmountByUrl(properties);
                    };

                    AmountByUrl.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.amount != null && m.hasOwnProperty("amount"))
                            w.uint32(8).uint32(m.amount);
                        if (m.url != null && m.hasOwnProperty("url"))
                            w.uint32(18).string(m.url);
                        if (m.title != null && m.hasOwnProperty("title"))
                            w.uint32(26).string(m.title);
                        return w;
                    };

                    AmountByUrl.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.report.cheer_by_url.Response.AmountByUrl();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1:
                                m.amount = r.uint32();
                                break;
                            case 2:
                                m.url = r.string();
                                break;
                            case 3:
                                m.title = r.string();
                                break;
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    return AmountByUrl;
                })();

                return Response;
            })();

            return cheer_by_url;
        })();

        report.export_ = (function() {

            const export_ = {};

            return export_;
        })();

        return report;
    })();

    provider_api.tag = (function() {

        const tag = {};

        tag.players = (function() {

            const players = {};

            players.Service = (function() {

                function Service(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Service.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Service;

                Service.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };


                Object.defineProperty(Service.prototype.tagPlayers = function tagPlayers(request, callback) {
                    return this.rpcCall(tagPlayers, $root.provider_api.tag.players.Request, $root.provider_api.tag.players.Response, request, callback);
                }, "name", { value: "TagPlayers" });

                return Service;
            })();

            players.Request = (function() {

                function Request(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Request.create = function create(properties) {
                    return new Request(properties);
                };

                Request.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    return w;
                };

                Request.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.tag.players.Request();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Request;
            })();

            players.Response = (function() {

                function Response(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Response.prototype.status = null;
                Response.prototype.data = null;

                Response.create = function create(properties) {
                    return new Response(properties);
                };

                Response.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.status != null && m.hasOwnProperty("status"))
                        $root.provider_api.common.Status.encode(m.status, w.uint32(10).fork()).ldelim();
                    if (m.data != null && m.hasOwnProperty("data"))
                        $root.provider_api.tag.players.Response.Data.encode(m.data, w.uint32(18).fork()).ldelim();
                    return w;
                };

                Response.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.tag.players.Response();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.status = $root.provider_api.common.Status.decode(r, r.uint32());
                            break;
                        case 2:
                            m.data = $root.provider_api.tag.players.Response.Data.decode(r, r.uint32());
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                Response.Data = (function() {

                    function Data(p) {
                        this.players = [];
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    Data.prototype.players = $util.emptyArray;

                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    Data.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.players != null && m.players.length) {
                            for (var i = 0; i < m.players.length; ++i)
                                $root.provider_api.tag.players.Response.Data.Player.encode(m.players[i], w.uint32(10).fork()).ldelim();
                        }
                        return w;
                    };

                    Data.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.tag.players.Response.Data();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1:
                                if (!(m.players && m.players.length))
                                    m.players = [];
                                m.players.push($root.provider_api.tag.players.Response.Data.Player.decode(r, r.uint32()));
                                break;
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };

                    Data.Player = (function() {

                        function Player(p) {
                            if (p)
                                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                    if (p[ks[i]] != null)
                                        this[ks[i]] = p[ks[i]];
                        }

                        Player.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
                        Player.prototype.name = "";
                        Player.prototype.slug = "";
                        Player.prototype.publishedAt = "";
                        Player.prototype.hiraganaName = "";

                        Player.create = function create(properties) {
                            return new Player(properties);
                        };

                        Player.encode = function encode(m, w) {
                            if (!w)
                                w = $Writer.create();
                            if (m.id != null && m.hasOwnProperty("id"))
                                w.uint32(8).uint64(m.id);
                            if (m.name != null && m.hasOwnProperty("name"))
                                w.uint32(18).string(m.name);
                            if (m.slug != null && m.hasOwnProperty("slug"))
                                w.uint32(26).string(m.slug);
                            if (m.publishedAt != null && m.hasOwnProperty("publishedAt"))
                                w.uint32(34).string(m.publishedAt);
                            if (m.hiraganaName != null && m.hasOwnProperty("hiraganaName"))
                                w.uint32(42).string(m.hiraganaName);
                            return w;
                        };

                        Player.decode = function decode(r, l) {
                            if (!(r instanceof $Reader))
                                r = $Reader.create(r);
                            var c = l === undefined ? r.len : r.pos + l, m = new $root.provider_api.tag.players.Response.Data.Player();
                            while (r.pos < c) {
                                var t = r.uint32();
                                switch (t >>> 3) {
                                case 1:
                                    m.id = r.uint64();
                                    break;
                                case 2:
                                    m.name = r.string();
                                    break;
                                case 3:
                                    m.slug = r.string();
                                    break;
                                case 4:
                                    m.publishedAt = r.string();
                                    break;
                                case 5:
                                    m.hiraganaName = r.string();
                                    break;
                                default:
                                    r.skipType(t & 7);
                                    break;
                                }
                            }
                            return m;
                        };

                        return Player;
                    })();

                    return Data;
                })();

                return Response;
            })();

            return players;
        })();

        return tag;
    })();

    return provider_api;
})();

export const structs = $root.structs = (() => {

    const structs = {};

    structs.CheerMessage = (function() {

        function CheerMessage(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        CheerMessage.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        CheerMessage.prototype.slug = "";
        CheerMessage.prototype.message = "";
        CheerMessage.prototype.isGifting = false;
        CheerMessage.prototype.insertedAt = null;

        CheerMessage.create = function create(properties) {
            return new CheerMessage(properties);
        };

        CheerMessage.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && m.hasOwnProperty("id"))
                w.uint32(8).uint64(m.id);
            if (m.slug != null && m.hasOwnProperty("slug"))
                w.uint32(18).string(m.slug);
            if (m.message != null && m.hasOwnProperty("message"))
                w.uint32(26).string(m.message);
            if (m.isGifting != null && m.hasOwnProperty("isGifting"))
                w.uint32(32).bool(m.isGifting);
            if (m.insertedAt != null && m.hasOwnProperty("insertedAt"))
                $root.google.protobuf.Timestamp.encode(m.insertedAt, w.uint32(42).fork()).ldelim();
            return w;
        };

        CheerMessage.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.structs.CheerMessage();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.id = r.uint64();
                    break;
                case 2:
                    m.slug = r.string();
                    break;
                case 3:
                    m.message = r.string();
                    break;
                case 4:
                    m.isGifting = r.bool();
                    break;
                case 5:
                    m.insertedAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return CheerMessage;
    })();

    structs.ImageURL = (function() {

        function ImageURL(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        ImageURL.prototype.small = "";
        ImageURL.prototype.middle = "";
        ImageURL.prototype.large = "";

        ImageURL.create = function create(properties) {
            return new ImageURL(properties);
        };

        ImageURL.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.small != null && m.hasOwnProperty("small"))
                w.uint32(10).string(m.small);
            if (m.middle != null && m.hasOwnProperty("middle"))
                w.uint32(18).string(m.middle);
            if (m.large != null && m.hasOwnProperty("large"))
                w.uint32(26).string(m.large);
            return w;
        };

        ImageURL.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.structs.ImageURL();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.small = r.string();
                    break;
                case 2:
                    m.middle = r.string();
                    break;
                case 3:
                    m.large = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return ImageURL;
    })();

    structs.MovieURL = (function() {

        function MovieURL(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        MovieURL.prototype.pc = "";
        MovieURL.prototype.sp = "";

        MovieURL.create = function create(properties) {
            return new MovieURL(properties);
        };

        MovieURL.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.pc != null && m.hasOwnProperty("pc"))
                w.uint32(10).string(m.pc);
            if (m.sp != null && m.hasOwnProperty("sp"))
                w.uint32(18).string(m.sp);
            return w;
        };

        MovieURL.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.structs.MovieURL();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.pc = r.string();
                    break;
                case 2:
                    m.sp = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return MovieURL;
    })();

    structs.payment = (function() {

        const payment = {};

        payment.CreditCard = (function() {

            function CreditCard(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            CreditCard.prototype.creditCardId = "";
            CreditCard.prototype.brand = "";
            CreditCard.prototype.suffix = "";
            CreditCard.prototype.expireMonth = 0;
            CreditCard.prototype.expireYear = 0;
            CreditCard.prototype.holderName = "";
            CreditCard.prototype.createdAt = null;

            CreditCard.create = function create(properties) {
                return new CreditCard(properties);
            };

            CreditCard.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.creditCardId != null && m.hasOwnProperty("creditCardId"))
                    w.uint32(10).string(m.creditCardId);
                if (m.brand != null && m.hasOwnProperty("brand"))
                    w.uint32(18).string(m.brand);
                if (m.suffix != null && m.hasOwnProperty("suffix"))
                    w.uint32(26).string(m.suffix);
                if (m.expireMonth != null && m.hasOwnProperty("expireMonth"))
                    w.uint32(32).uint32(m.expireMonth);
                if (m.expireYear != null && m.hasOwnProperty("expireYear"))
                    w.uint32(40).uint32(m.expireYear);
                if (m.holderName != null && m.hasOwnProperty("holderName"))
                    w.uint32(50).string(m.holderName);
                if (m.createdAt != null && m.hasOwnProperty("createdAt"))
                    $root.google.protobuf.Timestamp.encode(m.createdAt, w.uint32(58).fork()).ldelim();
                return w;
            };

            CreditCard.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.structs.payment.CreditCard();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.creditCardId = r.string();
                        break;
                    case 2:
                        m.brand = r.string();
                        break;
                    case 3:
                        m.suffix = r.string();
                        break;
                    case 4:
                        m.expireMonth = r.uint32();
                        break;
                    case 5:
                        m.expireYear = r.uint32();
                        break;
                    case 6:
                        m.holderName = r.string();
                        break;
                    case 7:
                        m.createdAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return CreditCard;
        })();

        return payment;
    })();

    structs.Player = (function() {

        function Player(p) {
            this.tag = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Player.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Player.prototype.name = "";
        Player.prototype.iconUrl = null;
        Player.prototype.slug = "";
        Player.prototype.tag = $util.emptyArray;

        Player.create = function create(properties) {
            return new Player(properties);
        };

        Player.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && m.hasOwnProperty("id"))
                w.uint32(8).uint64(m.id);
            if (m.name != null && m.hasOwnProperty("name"))
                w.uint32(18).string(m.name);
            if (m.iconUrl != null && m.hasOwnProperty("iconUrl"))
                $root.structs.ImageURL.encode(m.iconUrl, w.uint32(26).fork()).ldelim();
            if (m.slug != null && m.hasOwnProperty("slug"))
                w.uint32(34).string(m.slug);
            if (m.tag != null && m.tag.length) {
                for (var i = 0; i < m.tag.length; ++i)
                    w.uint32(42).string(m.tag[i]);
            }
            return w;
        };

        Player.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.structs.Player();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.id = r.uint64();
                    break;
                case 2:
                    m.name = r.string();
                    break;
                case 3:
                    m.iconUrl = $root.structs.ImageURL.decode(r, r.uint32());
                    break;
                case 4:
                    m.slug = r.string();
                    break;
                case 5:
                    if (!(m.tag && m.tag.length))
                        m.tag = [];
                    m.tag.push(r.string());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return Player;
    })();

    structs.PlayerCategory = (function() {

        function PlayerCategory(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        PlayerCategory.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        PlayerCategory.prototype.name = "";
        PlayerCategory.prototype.slug = "";

        PlayerCategory.create = function create(properties) {
            return new PlayerCategory(properties);
        };

        PlayerCategory.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && m.hasOwnProperty("id"))
                w.uint32(8).uint64(m.id);
            if (m.name != null && m.hasOwnProperty("name"))
                w.uint32(18).string(m.name);
            if (m.slug != null && m.hasOwnProperty("slug"))
                w.uint32(26).string(m.slug);
            return w;
        };

        PlayerCategory.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.structs.PlayerCategory();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.id = r.uint64();
                    break;
                case 2:
                    m.name = r.string();
                    break;
                case 3:
                    m.slug = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return PlayerCategory;
    })();

    structs.provider = (function() {

        const provider = {};

        provider.log = (function() {

            const log = {};

            log.Tracking = (function() {

                function Tracking(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Tracking.prototype.providerId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
                Tracking.prototype.url = "";
                Tracking.prototype.title = "";
                Tracking.prototype.playerId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                Tracking.create = function create(properties) {
                    return new Tracking(properties);
                };

                Tracking.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.providerId != null && m.hasOwnProperty("providerId"))
                        w.uint32(8).uint64(m.providerId);
                    if (m.url != null && m.hasOwnProperty("url"))
                        w.uint32(18).string(m.url);
                    if (m.title != null && m.hasOwnProperty("title"))
                        w.uint32(26).string(m.title);
                    if (m.playerId != null && m.hasOwnProperty("playerId"))
                        w.uint32(32).uint64(m.playerId);
                    return w;
                };

                Tracking.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.structs.provider.log.Tracking();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.providerId = r.uint64();
                            break;
                        case 2:
                            m.url = r.string();
                            break;
                        case 3:
                            m.title = r.string();
                            break;
                        case 4:
                            m.playerId = r.uint64();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Tracking;
            })();

            return log;
        })();

        return provider;
    })();

    structs.WidgetImageURL = (function() {

        function WidgetImageURL(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        WidgetImageURL.prototype.pc = "";
        WidgetImageURL.prototype.sp = "";
        WidgetImageURL.prototype.rect = "";

        WidgetImageURL.create = function create(properties) {
            return new WidgetImageURL(properties);
        };

        WidgetImageURL.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.pc != null && m.hasOwnProperty("pc"))
                w.uint32(10).string(m.pc);
            if (m.sp != null && m.hasOwnProperty("sp"))
                w.uint32(18).string(m.sp);
            if (m.rect != null && m.hasOwnProperty("rect"))
                w.uint32(26).string(m.rect);
            return w;
        };

        WidgetImageURL.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.structs.WidgetImageURL();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.pc = r.string();
                    break;
                case 2:
                    m.sp = r.string();
                    break;
                case 3:
                    m.rect = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return WidgetImageURL;
    })();

    return structs;
})();

export const google = $root.google = (() => {

    const google = {};

    google.api = (function() {

        const api = {};

        api.Http = (function() {

            function Http(p) {
                this.rules = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Http.prototype.rules = $util.emptyArray;

            Http.create = function create(properties) {
                return new Http(properties);
            };

            Http.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.rules != null && m.rules.length) {
                    for (var i = 0; i < m.rules.length; ++i)
                        $root.google.api.HttpRule.encode(m.rules[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            Http.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.api.Http();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        if (!(m.rules && m.rules.length))
                            m.rules = [];
                        m.rules.push($root.google.api.HttpRule.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return Http;
        })();

        api.HttpRule = (function() {

            function HttpRule(p) {
                this.additionalBindings = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            HttpRule.prototype.get = "";
            HttpRule.prototype.put = "";
            HttpRule.prototype.post = "";
            HttpRule.prototype["delete"] = "";
            HttpRule.prototype.patch = "";
            HttpRule.prototype.custom = null;
            HttpRule.prototype.selector = "";
            HttpRule.prototype.body = "";
            HttpRule.prototype.additionalBindings = $util.emptyArray;

            let $oneOfFields;

            Object.defineProperty(HttpRule.prototype, "pattern", {
                get: $util.oneOfGetter($oneOfFields = ["get", "put", "post", "delete", "patch", "custom"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            HttpRule.create = function create(properties) {
                return new HttpRule(properties);
            };

            HttpRule.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.selector != null && m.hasOwnProperty("selector"))
                    w.uint32(10).string(m.selector);
                if (m.get != null && m.hasOwnProperty("get"))
                    w.uint32(18).string(m.get);
                if (m.put != null && m.hasOwnProperty("put"))
                    w.uint32(26).string(m.put);
                if (m.post != null && m.hasOwnProperty("post"))
                    w.uint32(34).string(m.post);
                if (m["delete"] != null && m.hasOwnProperty("delete"))
                    w.uint32(42).string(m["delete"]);
                if (m.patch != null && m.hasOwnProperty("patch"))
                    w.uint32(50).string(m.patch);
                if (m.body != null && m.hasOwnProperty("body"))
                    w.uint32(58).string(m.body);
                if (m.custom != null && m.hasOwnProperty("custom"))
                    $root.google.api.CustomHttpPattern.encode(m.custom, w.uint32(66).fork()).ldelim();
                if (m.additionalBindings != null && m.additionalBindings.length) {
                    for (var i = 0; i < m.additionalBindings.length; ++i)
                        $root.google.api.HttpRule.encode(m.additionalBindings[i], w.uint32(90).fork()).ldelim();
                }
                return w;
            };

            HttpRule.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.api.HttpRule();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 2:
                        m.get = r.string();
                        break;
                    case 3:
                        m.put = r.string();
                        break;
                    case 4:
                        m.post = r.string();
                        break;
                    case 5:
                        m["delete"] = r.string();
                        break;
                    case 6:
                        m.patch = r.string();
                        break;
                    case 8:
                        m.custom = $root.google.api.CustomHttpPattern.decode(r, r.uint32());
                        break;
                    case 1:
                        m.selector = r.string();
                        break;
                    case 7:
                        m.body = r.string();
                        break;
                    case 11:
                        if (!(m.additionalBindings && m.additionalBindings.length))
                            m.additionalBindings = [];
                        m.additionalBindings.push($root.google.api.HttpRule.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return HttpRule;
        })();

        api.CustomHttpPattern = (function() {

            function CustomHttpPattern(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            CustomHttpPattern.prototype.kind = "";
            CustomHttpPattern.prototype.path = "";

            CustomHttpPattern.create = function create(properties) {
                return new CustomHttpPattern(properties);
            };

            CustomHttpPattern.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.kind != null && m.hasOwnProperty("kind"))
                    w.uint32(10).string(m.kind);
                if (m.path != null && m.hasOwnProperty("path"))
                    w.uint32(18).string(m.path);
                return w;
            };

            CustomHttpPattern.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.api.CustomHttpPattern();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.kind = r.string();
                        break;
                    case 2:
                        m.path = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return CustomHttpPattern;
        })();

        return api;
    })();

    google.protobuf = (function() {

        const protobuf = {};

        protobuf.FileDescriptorSet = (function() {

            function FileDescriptorSet(p) {
                this.file = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            FileDescriptorSet.prototype.file = $util.emptyArray;

            FileDescriptorSet.create = function create(properties) {
                return new FileDescriptorSet(properties);
            };

            FileDescriptorSet.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.file != null && m.file.length) {
                    for (var i = 0; i < m.file.length; ++i)
                        $root.google.protobuf.FileDescriptorProto.encode(m.file[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            FileDescriptorSet.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FileDescriptorSet();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        if (!(m.file && m.file.length))
                            m.file = [];
                        m.file.push($root.google.protobuf.FileDescriptorProto.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return FileDescriptorSet;
        })();

        protobuf.FileDescriptorProto = (function() {

            function FileDescriptorProto(p) {
                this.dependency = [];
                this.publicDependency = [];
                this.weakDependency = [];
                this.messageType = [];
                this.enumType = [];
                this.service = [];
                this.extension = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            FileDescriptorProto.prototype.name = "";
            FileDescriptorProto.prototype["package"] = "";
            FileDescriptorProto.prototype.dependency = $util.emptyArray;
            FileDescriptorProto.prototype.publicDependency = $util.emptyArray;
            FileDescriptorProto.prototype.weakDependency = $util.emptyArray;
            FileDescriptorProto.prototype.messageType = $util.emptyArray;
            FileDescriptorProto.prototype.enumType = $util.emptyArray;
            FileDescriptorProto.prototype.service = $util.emptyArray;
            FileDescriptorProto.prototype.extension = $util.emptyArray;
            FileDescriptorProto.prototype.options = null;
            FileDescriptorProto.prototype.sourceCodeInfo = null;
            FileDescriptorProto.prototype.syntax = "";

            FileDescriptorProto.create = function create(properties) {
                return new FileDescriptorProto(properties);
            };

            FileDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && m.hasOwnProperty("name"))
                    w.uint32(10).string(m.name);
                if (m["package"] != null && m.hasOwnProperty("package"))
                    w.uint32(18).string(m["package"]);
                if (m.dependency != null && m.dependency.length) {
                    for (var i = 0; i < m.dependency.length; ++i)
                        w.uint32(26).string(m.dependency[i]);
                }
                if (m.messageType != null && m.messageType.length) {
                    for (var i = 0; i < m.messageType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(m.messageType[i], w.uint32(34).fork()).ldelim();
                }
                if (m.enumType != null && m.enumType.length) {
                    for (var i = 0; i < m.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(m.enumType[i], w.uint32(42).fork()).ldelim();
                }
                if (m.service != null && m.service.length) {
                    for (var i = 0; i < m.service.length; ++i)
                        $root.google.protobuf.ServiceDescriptorProto.encode(m.service[i], w.uint32(50).fork()).ldelim();
                }
                if (m.extension != null && m.extension.length) {
                    for (var i = 0; i < m.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(m.extension[i], w.uint32(58).fork()).ldelim();
                }
                if (m.options != null && m.hasOwnProperty("options"))
                    $root.google.protobuf.FileOptions.encode(m.options, w.uint32(66).fork()).ldelim();
                if (m.sourceCodeInfo != null && m.hasOwnProperty("sourceCodeInfo"))
                    $root.google.protobuf.SourceCodeInfo.encode(m.sourceCodeInfo, w.uint32(74).fork()).ldelim();
                if (m.publicDependency != null && m.publicDependency.length) {
                    for (var i = 0; i < m.publicDependency.length; ++i)
                        w.uint32(80).int32(m.publicDependency[i]);
                }
                if (m.weakDependency != null && m.weakDependency.length) {
                    for (var i = 0; i < m.weakDependency.length; ++i)
                        w.uint32(88).int32(m.weakDependency[i]);
                }
                if (m.syntax != null && m.hasOwnProperty("syntax"))
                    w.uint32(98).string(m.syntax);
                return w;
            };

            FileDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FileDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        m["package"] = r.string();
                        break;
                    case 3:
                        if (!(m.dependency && m.dependency.length))
                            m.dependency = [];
                        m.dependency.push(r.string());
                        break;
                    case 10:
                        if (!(m.publicDependency && m.publicDependency.length))
                            m.publicDependency = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.publicDependency.push(r.int32());
                        } else
                            m.publicDependency.push(r.int32());
                        break;
                    case 11:
                        if (!(m.weakDependency && m.weakDependency.length))
                            m.weakDependency = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.weakDependency.push(r.int32());
                        } else
                            m.weakDependency.push(r.int32());
                        break;
                    case 4:
                        if (!(m.messageType && m.messageType.length))
                            m.messageType = [];
                        m.messageType.push($root.google.protobuf.DescriptorProto.decode(r, r.uint32()));
                        break;
                    case 5:
                        if (!(m.enumType && m.enumType.length))
                            m.enumType = [];
                        m.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 6:
                        if (!(m.service && m.service.length))
                            m.service = [];
                        m.service.push($root.google.protobuf.ServiceDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 7:
                        if (!(m.extension && m.extension.length))
                            m.extension = [];
                        m.extension.push($root.google.protobuf.FieldDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 8:
                        m.options = $root.google.protobuf.FileOptions.decode(r, r.uint32());
                        break;
                    case 9:
                        m.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(r, r.uint32());
                        break;
                    case 12:
                        m.syntax = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return FileDescriptorProto;
        })();

        protobuf.DescriptorProto = (function() {

            function DescriptorProto(p) {
                this.field = [];
                this.extension = [];
                this.nestedType = [];
                this.enumType = [];
                this.extensionRange = [];
                this.oneofDecl = [];
                this.reservedRange = [];
                this.reservedName = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            DescriptorProto.prototype.name = "";
            DescriptorProto.prototype.field = $util.emptyArray;
            DescriptorProto.prototype.extension = $util.emptyArray;
            DescriptorProto.prototype.nestedType = $util.emptyArray;
            DescriptorProto.prototype.enumType = $util.emptyArray;
            DescriptorProto.prototype.extensionRange = $util.emptyArray;
            DescriptorProto.prototype.oneofDecl = $util.emptyArray;
            DescriptorProto.prototype.options = null;
            DescriptorProto.prototype.reservedRange = $util.emptyArray;
            DescriptorProto.prototype.reservedName = $util.emptyArray;

            DescriptorProto.create = function create(properties) {
                return new DescriptorProto(properties);
            };

            DescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && m.hasOwnProperty("name"))
                    w.uint32(10).string(m.name);
                if (m.field != null && m.field.length) {
                    for (var i = 0; i < m.field.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(m.field[i], w.uint32(18).fork()).ldelim();
                }
                if (m.nestedType != null && m.nestedType.length) {
                    for (var i = 0; i < m.nestedType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(m.nestedType[i], w.uint32(26).fork()).ldelim();
                }
                if (m.enumType != null && m.enumType.length) {
                    for (var i = 0; i < m.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(m.enumType[i], w.uint32(34).fork()).ldelim();
                }
                if (m.extensionRange != null && m.extensionRange.length) {
                    for (var i = 0; i < m.extensionRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ExtensionRange.encode(m.extensionRange[i], w.uint32(42).fork()).ldelim();
                }
                if (m.extension != null && m.extension.length) {
                    for (var i = 0; i < m.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(m.extension[i], w.uint32(50).fork()).ldelim();
                }
                if (m.options != null && m.hasOwnProperty("options"))
                    $root.google.protobuf.MessageOptions.encode(m.options, w.uint32(58).fork()).ldelim();
                if (m.oneofDecl != null && m.oneofDecl.length) {
                    for (var i = 0; i < m.oneofDecl.length; ++i)
                        $root.google.protobuf.OneofDescriptorProto.encode(m.oneofDecl[i], w.uint32(66).fork()).ldelim();
                }
                if (m.reservedRange != null && m.reservedRange.length) {
                    for (var i = 0; i < m.reservedRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ReservedRange.encode(m.reservedRange[i], w.uint32(74).fork()).ldelim();
                }
                if (m.reservedName != null && m.reservedName.length) {
                    for (var i = 0; i < m.reservedName.length; ++i)
                        w.uint32(82).string(m.reservedName[i]);
                }
                return w;
            };

            DescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.DescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        if (!(m.field && m.field.length))
                            m.field = [];
                        m.field.push($root.google.protobuf.FieldDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 6:
                        if (!(m.extension && m.extension.length))
                            m.extension = [];
                        m.extension.push($root.google.protobuf.FieldDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 3:
                        if (!(m.nestedType && m.nestedType.length))
                            m.nestedType = [];
                        m.nestedType.push($root.google.protobuf.DescriptorProto.decode(r, r.uint32()));
                        break;
                    case 4:
                        if (!(m.enumType && m.enumType.length))
                            m.enumType = [];
                        m.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 5:
                        if (!(m.extensionRange && m.extensionRange.length))
                            m.extensionRange = [];
                        m.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(r, r.uint32()));
                        break;
                    case 8:
                        if (!(m.oneofDecl && m.oneofDecl.length))
                            m.oneofDecl = [];
                        m.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 7:
                        m.options = $root.google.protobuf.MessageOptions.decode(r, r.uint32());
                        break;
                    case 9:
                        if (!(m.reservedRange && m.reservedRange.length))
                            m.reservedRange = [];
                        m.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(r, r.uint32()));
                        break;
                    case 10:
                        if (!(m.reservedName && m.reservedName.length))
                            m.reservedName = [];
                        m.reservedName.push(r.string());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            DescriptorProto.ExtensionRange = (function() {

                function ExtensionRange(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                ExtensionRange.prototype.start = 0;
                ExtensionRange.prototype.end = 0;

                ExtensionRange.create = function create(properties) {
                    return new ExtensionRange(properties);
                };

                ExtensionRange.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.start != null && m.hasOwnProperty("start"))
                        w.uint32(8).int32(m.start);
                    if (m.end != null && m.hasOwnProperty("end"))
                        w.uint32(16).int32(m.end);
                    return w;
                };

                ExtensionRange.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.start = r.int32();
                            break;
                        case 2:
                            m.end = r.int32();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return ExtensionRange;
            })();

            DescriptorProto.ReservedRange = (function() {

                function ReservedRange(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                ReservedRange.prototype.start = 0;
                ReservedRange.prototype.end = 0;

                ReservedRange.create = function create(properties) {
                    return new ReservedRange(properties);
                };

                ReservedRange.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.start != null && m.hasOwnProperty("start"))
                        w.uint32(8).int32(m.start);
                    if (m.end != null && m.hasOwnProperty("end"))
                        w.uint32(16).int32(m.end);
                    return w;
                };

                ReservedRange.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.start = r.int32();
                            break;
                        case 2:
                            m.end = r.int32();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return ReservedRange;
            })();

            return DescriptorProto;
        })();

        protobuf.FieldDescriptorProto = (function() {

            function FieldDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            FieldDescriptorProto.prototype.name = "";
            FieldDescriptorProto.prototype.number = 0;
            FieldDescriptorProto.prototype.label = 1;
            FieldDescriptorProto.prototype.type = 1;
            FieldDescriptorProto.prototype.typeName = "";
            FieldDescriptorProto.prototype.extendee = "";
            FieldDescriptorProto.prototype.defaultValue = "";
            FieldDescriptorProto.prototype.oneofIndex = 0;
            FieldDescriptorProto.prototype.jsonName = "";
            FieldDescriptorProto.prototype.options = null;

            FieldDescriptorProto.create = function create(properties) {
                return new FieldDescriptorProto(properties);
            };

            FieldDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && m.hasOwnProperty("name"))
                    w.uint32(10).string(m.name);
                if (m.extendee != null && m.hasOwnProperty("extendee"))
                    w.uint32(18).string(m.extendee);
                if (m.number != null && m.hasOwnProperty("number"))
                    w.uint32(24).int32(m.number);
                if (m.label != null && m.hasOwnProperty("label"))
                    w.uint32(32).int32(m.label);
                if (m.type != null && m.hasOwnProperty("type"))
                    w.uint32(40).int32(m.type);
                if (m.typeName != null && m.hasOwnProperty("typeName"))
                    w.uint32(50).string(m.typeName);
                if (m.defaultValue != null && m.hasOwnProperty("defaultValue"))
                    w.uint32(58).string(m.defaultValue);
                if (m.options != null && m.hasOwnProperty("options"))
                    $root.google.protobuf.FieldOptions.encode(m.options, w.uint32(66).fork()).ldelim();
                if (m.oneofIndex != null && m.hasOwnProperty("oneofIndex"))
                    w.uint32(72).int32(m.oneofIndex);
                if (m.jsonName != null && m.hasOwnProperty("jsonName"))
                    w.uint32(82).string(m.jsonName);
                return w;
            };

            FieldDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FieldDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 3:
                        m.number = r.int32();
                        break;
                    case 4:
                        m.label = r.int32();
                        break;
                    case 5:
                        m.type = r.int32();
                        break;
                    case 6:
                        m.typeName = r.string();
                        break;
                    case 2:
                        m.extendee = r.string();
                        break;
                    case 7:
                        m.defaultValue = r.string();
                        break;
                    case 9:
                        m.oneofIndex = r.int32();
                        break;
                    case 10:
                        m.jsonName = r.string();
                        break;
                    case 8:
                        m.options = $root.google.protobuf.FieldOptions.decode(r, r.uint32());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            FieldDescriptorProto.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                values[valuesById[2] = "TYPE_FLOAT"] = 2;
                values[valuesById[3] = "TYPE_INT64"] = 3;
                values[valuesById[4] = "TYPE_UINT64"] = 4;
                values[valuesById[5] = "TYPE_INT32"] = 5;
                values[valuesById[6] = "TYPE_FIXED64"] = 6;
                values[valuesById[7] = "TYPE_FIXED32"] = 7;
                values[valuesById[8] = "TYPE_BOOL"] = 8;
                values[valuesById[9] = "TYPE_STRING"] = 9;
                values[valuesById[10] = "TYPE_GROUP"] = 10;
                values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                values[valuesById[12] = "TYPE_BYTES"] = 12;
                values[valuesById[13] = "TYPE_UINT32"] = 13;
                values[valuesById[14] = "TYPE_ENUM"] = 14;
                values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                values[valuesById[17] = "TYPE_SINT32"] = 17;
                values[valuesById[18] = "TYPE_SINT64"] = 18;
                return values;
            })();

            FieldDescriptorProto.Label = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                values[valuesById[3] = "LABEL_REPEATED"] = 3;
                return values;
            })();

            return FieldDescriptorProto;
        })();

        protobuf.OneofDescriptorProto = (function() {

            function OneofDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            OneofDescriptorProto.prototype.name = "";
            OneofDescriptorProto.prototype.options = null;

            OneofDescriptorProto.create = function create(properties) {
                return new OneofDescriptorProto(properties);
            };

            OneofDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && m.hasOwnProperty("name"))
                    w.uint32(10).string(m.name);
                if (m.options != null && m.hasOwnProperty("options"))
                    $root.google.protobuf.OneofOptions.encode(m.options, w.uint32(18).fork()).ldelim();
                return w;
            };

            OneofDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.OneofDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        m.options = $root.google.protobuf.OneofOptions.decode(r, r.uint32());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return OneofDescriptorProto;
        })();

        protobuf.EnumDescriptorProto = (function() {

            function EnumDescriptorProto(p) {
                this.value = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            EnumDescriptorProto.prototype.name = "";
            EnumDescriptorProto.prototype.value = $util.emptyArray;
            EnumDescriptorProto.prototype.options = null;

            EnumDescriptorProto.create = function create(properties) {
                return new EnumDescriptorProto(properties);
            };

            EnumDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && m.hasOwnProperty("name"))
                    w.uint32(10).string(m.name);
                if (m.value != null && m.value.length) {
                    for (var i = 0; i < m.value.length; ++i)
                        $root.google.protobuf.EnumValueDescriptorProto.encode(m.value[i], w.uint32(18).fork()).ldelim();
                }
                if (m.options != null && m.hasOwnProperty("options"))
                    $root.google.protobuf.EnumOptions.encode(m.options, w.uint32(26).fork()).ldelim();
                return w;
            };

            EnumDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.EnumDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        if (!(m.value && m.value.length))
                            m.value = [];
                        m.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 3:
                        m.options = $root.google.protobuf.EnumOptions.decode(r, r.uint32());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return EnumDescriptorProto;
        })();

        protobuf.EnumValueDescriptorProto = (function() {

            function EnumValueDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            EnumValueDescriptorProto.prototype.name = "";
            EnumValueDescriptorProto.prototype.number = 0;
            EnumValueDescriptorProto.prototype.options = null;

            EnumValueDescriptorProto.create = function create(properties) {
                return new EnumValueDescriptorProto(properties);
            };

            EnumValueDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && m.hasOwnProperty("name"))
                    w.uint32(10).string(m.name);
                if (m.number != null && m.hasOwnProperty("number"))
                    w.uint32(16).int32(m.number);
                if (m.options != null && m.hasOwnProperty("options"))
                    $root.google.protobuf.EnumValueOptions.encode(m.options, w.uint32(26).fork()).ldelim();
                return w;
            };

            EnumValueDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.EnumValueDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        m.number = r.int32();
                        break;
                    case 3:
                        m.options = $root.google.protobuf.EnumValueOptions.decode(r, r.uint32());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return EnumValueDescriptorProto;
        })();

        protobuf.ServiceDescriptorProto = (function() {

            function ServiceDescriptorProto(p) {
                this.method = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            ServiceDescriptorProto.prototype.name = "";
            ServiceDescriptorProto.prototype.method = $util.emptyArray;
            ServiceDescriptorProto.prototype.options = null;

            ServiceDescriptorProto.create = function create(properties) {
                return new ServiceDescriptorProto(properties);
            };

            ServiceDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && m.hasOwnProperty("name"))
                    w.uint32(10).string(m.name);
                if (m.method != null && m.method.length) {
                    for (var i = 0; i < m.method.length; ++i)
                        $root.google.protobuf.MethodDescriptorProto.encode(m.method[i], w.uint32(18).fork()).ldelim();
                }
                if (m.options != null && m.hasOwnProperty("options"))
                    $root.google.protobuf.ServiceOptions.encode(m.options, w.uint32(26).fork()).ldelim();
                return w;
            };

            ServiceDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.ServiceDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        if (!(m.method && m.method.length))
                            m.method = [];
                        m.method.push($root.google.protobuf.MethodDescriptorProto.decode(r, r.uint32()));
                        break;
                    case 3:
                        m.options = $root.google.protobuf.ServiceOptions.decode(r, r.uint32());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return ServiceDescriptorProto;
        })();

        protobuf.MethodDescriptorProto = (function() {

            function MethodDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            MethodDescriptorProto.prototype.name = "";
            MethodDescriptorProto.prototype.inputType = "";
            MethodDescriptorProto.prototype.outputType = "";
            MethodDescriptorProto.prototype.options = null;
            MethodDescriptorProto.prototype.clientStreaming = false;
            MethodDescriptorProto.prototype.serverStreaming = false;

            MethodDescriptorProto.create = function create(properties) {
                return new MethodDescriptorProto(properties);
            };

            MethodDescriptorProto.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && m.hasOwnProperty("name"))
                    w.uint32(10).string(m.name);
                if (m.inputType != null && m.hasOwnProperty("inputType"))
                    w.uint32(18).string(m.inputType);
                if (m.outputType != null && m.hasOwnProperty("outputType"))
                    w.uint32(26).string(m.outputType);
                if (m.options != null && m.hasOwnProperty("options"))
                    $root.google.protobuf.MethodOptions.encode(m.options, w.uint32(34).fork()).ldelim();
                if (m.clientStreaming != null && m.hasOwnProperty("clientStreaming"))
                    w.uint32(40).bool(m.clientStreaming);
                if (m.serverStreaming != null && m.hasOwnProperty("serverStreaming"))
                    w.uint32(48).bool(m.serverStreaming);
                return w;
            };

            MethodDescriptorProto.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.MethodDescriptorProto();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        m.inputType = r.string();
                        break;
                    case 3:
                        m.outputType = r.string();
                        break;
                    case 4:
                        m.options = $root.google.protobuf.MethodOptions.decode(r, r.uint32());
                        break;
                    case 5:
                        m.clientStreaming = r.bool();
                        break;
                    case 6:
                        m.serverStreaming = r.bool();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return MethodDescriptorProto;
        })();

        protobuf.FileOptions = (function() {

            function FileOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            FileOptions.prototype.javaPackage = "";
            FileOptions.prototype.javaOuterClassname = "";
            FileOptions.prototype.javaMultipleFiles = false;
            FileOptions.prototype.javaGenerateEqualsAndHash = false;
            FileOptions.prototype.javaStringCheckUtf8 = false;
            FileOptions.prototype.optimizeFor = 1;
            FileOptions.prototype.goPackage = "";
            FileOptions.prototype.ccGenericServices = false;
            FileOptions.prototype.javaGenericServices = false;
            FileOptions.prototype.pyGenericServices = false;
            FileOptions.prototype.deprecated = false;
            FileOptions.prototype.ccEnableArenas = false;
            FileOptions.prototype.objcClassPrefix = "";
            FileOptions.prototype.csharpNamespace = "";
            FileOptions.prototype.uninterpretedOption = $util.emptyArray;

            FileOptions.create = function create(properties) {
                return new FileOptions(properties);
            };

            FileOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.javaPackage != null && m.hasOwnProperty("javaPackage"))
                    w.uint32(10).string(m.javaPackage);
                if (m.javaOuterClassname != null && m.hasOwnProperty("javaOuterClassname"))
                    w.uint32(66).string(m.javaOuterClassname);
                if (m.optimizeFor != null && m.hasOwnProperty("optimizeFor"))
                    w.uint32(72).int32(m.optimizeFor);
                if (m.javaMultipleFiles != null && m.hasOwnProperty("javaMultipleFiles"))
                    w.uint32(80).bool(m.javaMultipleFiles);
                if (m.goPackage != null && m.hasOwnProperty("goPackage"))
                    w.uint32(90).string(m.goPackage);
                if (m.ccGenericServices != null && m.hasOwnProperty("ccGenericServices"))
                    w.uint32(128).bool(m.ccGenericServices);
                if (m.javaGenericServices != null && m.hasOwnProperty("javaGenericServices"))
                    w.uint32(136).bool(m.javaGenericServices);
                if (m.pyGenericServices != null && m.hasOwnProperty("pyGenericServices"))
                    w.uint32(144).bool(m.pyGenericServices);
                if (m.javaGenerateEqualsAndHash != null && m.hasOwnProperty("javaGenerateEqualsAndHash"))
                    w.uint32(160).bool(m.javaGenerateEqualsAndHash);
                if (m.deprecated != null && m.hasOwnProperty("deprecated"))
                    w.uint32(184).bool(m.deprecated);
                if (m.javaStringCheckUtf8 != null && m.hasOwnProperty("javaStringCheckUtf8"))
                    w.uint32(216).bool(m.javaStringCheckUtf8);
                if (m.ccEnableArenas != null && m.hasOwnProperty("ccEnableArenas"))
                    w.uint32(248).bool(m.ccEnableArenas);
                if (m.objcClassPrefix != null && m.hasOwnProperty("objcClassPrefix"))
                    w.uint32(290).string(m.objcClassPrefix);
                if (m.csharpNamespace != null && m.hasOwnProperty("csharpNamespace"))
                    w.uint32(298).string(m.csharpNamespace);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            FileOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FileOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.javaPackage = r.string();
                        break;
                    case 8:
                        m.javaOuterClassname = r.string();
                        break;
                    case 10:
                        m.javaMultipleFiles = r.bool();
                        break;
                    case 20:
                        m.javaGenerateEqualsAndHash = r.bool();
                        break;
                    case 27:
                        m.javaStringCheckUtf8 = r.bool();
                        break;
                    case 9:
                        m.optimizeFor = r.int32();
                        break;
                    case 11:
                        m.goPackage = r.string();
                        break;
                    case 16:
                        m.ccGenericServices = r.bool();
                        break;
                    case 17:
                        m.javaGenericServices = r.bool();
                        break;
                    case 18:
                        m.pyGenericServices = r.bool();
                        break;
                    case 23:
                        m.deprecated = r.bool();
                        break;
                    case 31:
                        m.ccEnableArenas = r.bool();
                        break;
                    case 36:
                        m.objcClassPrefix = r.string();
                        break;
                    case 37:
                        m.csharpNamespace = r.string();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            FileOptions.OptimizeMode = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "SPEED"] = 1;
                values[valuesById[2] = "CODE_SIZE"] = 2;
                values[valuesById[3] = "LITE_RUNTIME"] = 3;
                return values;
            })();

            return FileOptions;
        })();

        protobuf.MessageOptions = (function() {

            function MessageOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            MessageOptions.prototype.messageSetWireFormat = false;
            MessageOptions.prototype.noStandardDescriptorAccessor = false;
            MessageOptions.prototype.deprecated = false;
            MessageOptions.prototype.mapEntry = false;
            MessageOptions.prototype.uninterpretedOption = $util.emptyArray;

            MessageOptions.create = function create(properties) {
                return new MessageOptions(properties);
            };

            MessageOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.messageSetWireFormat != null && m.hasOwnProperty("messageSetWireFormat"))
                    w.uint32(8).bool(m.messageSetWireFormat);
                if (m.noStandardDescriptorAccessor != null && m.hasOwnProperty("noStandardDescriptorAccessor"))
                    w.uint32(16).bool(m.noStandardDescriptorAccessor);
                if (m.deprecated != null && m.hasOwnProperty("deprecated"))
                    w.uint32(24).bool(m.deprecated);
                if (m.mapEntry != null && m.hasOwnProperty("mapEntry"))
                    w.uint32(56).bool(m.mapEntry);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            MessageOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.MessageOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.messageSetWireFormat = r.bool();
                        break;
                    case 2:
                        m.noStandardDescriptorAccessor = r.bool();
                        break;
                    case 3:
                        m.deprecated = r.bool();
                        break;
                    case 7:
                        m.mapEntry = r.bool();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return MessageOptions;
        })();

        protobuf.FieldOptions = (function() {

            function FieldOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            FieldOptions.prototype.ctype = 0;
            FieldOptions.prototype.packed = false;
            FieldOptions.prototype.jstype = 0;
            FieldOptions.prototype.lazy = false;
            FieldOptions.prototype.deprecated = false;
            FieldOptions.prototype.weak = false;
            FieldOptions.prototype.uninterpretedOption = $util.emptyArray;

            FieldOptions.create = function create(properties) {
                return new FieldOptions(properties);
            };

            FieldOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ctype != null && m.hasOwnProperty("ctype"))
                    w.uint32(8).int32(m.ctype);
                if (m.packed != null && m.hasOwnProperty("packed"))
                    w.uint32(16).bool(m.packed);
                if (m.deprecated != null && m.hasOwnProperty("deprecated"))
                    w.uint32(24).bool(m.deprecated);
                if (m.lazy != null && m.hasOwnProperty("lazy"))
                    w.uint32(40).bool(m.lazy);
                if (m.jstype != null && m.hasOwnProperty("jstype"))
                    w.uint32(48).int32(m.jstype);
                if (m.weak != null && m.hasOwnProperty("weak"))
                    w.uint32(80).bool(m.weak);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            FieldOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FieldOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.ctype = r.int32();
                        break;
                    case 2:
                        m.packed = r.bool();
                        break;
                    case 6:
                        m.jstype = r.int32();
                        break;
                    case 5:
                        m.lazy = r.bool();
                        break;
                    case 3:
                        m.deprecated = r.bool();
                        break;
                    case 10:
                        m.weak = r.bool();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            FieldOptions.CType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STRING"] = 0;
                values[valuesById[1] = "CORD"] = 1;
                values[valuesById[2] = "STRING_PIECE"] = 2;
                return values;
            })();

            FieldOptions.JSType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JS_NORMAL"] = 0;
                values[valuesById[1] = "JS_STRING"] = 1;
                values[valuesById[2] = "JS_NUMBER"] = 2;
                return values;
            })();

            return FieldOptions;
        })();

        protobuf.OneofOptions = (function() {

            function OneofOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            OneofOptions.prototype.uninterpretedOption = $util.emptyArray;

            OneofOptions.create = function create(properties) {
                return new OneofOptions(properties);
            };

            OneofOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            OneofOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.OneofOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return OneofOptions;
        })();

        protobuf.EnumOptions = (function() {

            function EnumOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            EnumOptions.prototype.allowAlias = false;
            EnumOptions.prototype.deprecated = false;
            EnumOptions.prototype.uninterpretedOption = $util.emptyArray;

            EnumOptions.create = function create(properties) {
                return new EnumOptions(properties);
            };

            EnumOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.allowAlias != null && m.hasOwnProperty("allowAlias"))
                    w.uint32(16).bool(m.allowAlias);
                if (m.deprecated != null && m.hasOwnProperty("deprecated"))
                    w.uint32(24).bool(m.deprecated);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            EnumOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.EnumOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 2:
                        m.allowAlias = r.bool();
                        break;
                    case 3:
                        m.deprecated = r.bool();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return EnumOptions;
        })();

        protobuf.EnumValueOptions = (function() {

            function EnumValueOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            EnumValueOptions.prototype.deprecated = false;
            EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;

            EnumValueOptions.create = function create(properties) {
                return new EnumValueOptions(properties);
            };

            EnumValueOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.deprecated != null && m.hasOwnProperty("deprecated"))
                    w.uint32(8).bool(m.deprecated);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            EnumValueOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.EnumValueOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.deprecated = r.bool();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return EnumValueOptions;
        })();

        protobuf.ServiceOptions = (function() {

            function ServiceOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            ServiceOptions.prototype.deprecated = false;
            ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;

            ServiceOptions.create = function create(properties) {
                return new ServiceOptions(properties);
            };

            ServiceOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.deprecated != null && m.hasOwnProperty("deprecated"))
                    w.uint32(264).bool(m.deprecated);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                return w;
            };

            ServiceOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.ServiceOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 33:
                        m.deprecated = r.bool();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return ServiceOptions;
        })();

        protobuf.MethodOptions = (function() {

            function MethodOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            MethodOptions.prototype.deprecated = false;
            MethodOptions.prototype.uninterpretedOption = $util.emptyArray;
            MethodOptions.prototype[".google.api.http"] = null;

            MethodOptions.create = function create(properties) {
                return new MethodOptions(properties);
            };

            MethodOptions.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.deprecated != null && m.hasOwnProperty("deprecated"))
                    w.uint32(264).bool(m.deprecated);
                if (m.uninterpretedOption != null && m.uninterpretedOption.length) {
                    for (var i = 0; i < m.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(m.uninterpretedOption[i], w.uint32(7994).fork()).ldelim();
                }
                if (m[".google.api.http"] != null && m.hasOwnProperty(".google.api.http"))
                    $root.google.api.HttpRule.encode(m[".google.api.http"], w.uint32(578365826).fork()).ldelim();
                return w;
            };

            MethodOptions.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.MethodOptions();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 33:
                        m.deprecated = r.bool();
                        break;
                    case 999:
                        if (!(m.uninterpretedOption && m.uninterpretedOption.length))
                            m.uninterpretedOption = [];
                        m.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(r, r.uint32()));
                        break;
                    case 72295728:
                        m[".google.api.http"] = $root.google.api.HttpRule.decode(r, r.uint32());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return MethodOptions;
        })();

        protobuf.UninterpretedOption = (function() {

            function UninterpretedOption(p) {
                this.name = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            UninterpretedOption.prototype.name = $util.emptyArray;
            UninterpretedOption.prototype.identifierValue = "";
            UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            UninterpretedOption.prototype.doubleValue = 0;
            UninterpretedOption.prototype.stringValue = $util.newBuffer([]);
            UninterpretedOption.prototype.aggregateValue = "";

            UninterpretedOption.create = function create(properties) {
                return new UninterpretedOption(properties);
            };

            UninterpretedOption.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && m.name.length) {
                    for (var i = 0; i < m.name.length; ++i)
                        $root.google.protobuf.UninterpretedOption.NamePart.encode(m.name[i], w.uint32(18).fork()).ldelim();
                }
                if (m.identifierValue != null && m.hasOwnProperty("identifierValue"))
                    w.uint32(26).string(m.identifierValue);
                if (m.positiveIntValue != null && m.hasOwnProperty("positiveIntValue"))
                    w.uint32(32).uint64(m.positiveIntValue);
                if (m.negativeIntValue != null && m.hasOwnProperty("negativeIntValue"))
                    w.uint32(40).int64(m.negativeIntValue);
                if (m.doubleValue != null && m.hasOwnProperty("doubleValue"))
                    w.uint32(49).double(m.doubleValue);
                if (m.stringValue != null && m.hasOwnProperty("stringValue"))
                    w.uint32(58).bytes(m.stringValue);
                if (m.aggregateValue != null && m.hasOwnProperty("aggregateValue"))
                    w.uint32(66).string(m.aggregateValue);
                return w;
            };

            UninterpretedOption.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.UninterpretedOption();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 2:
                        if (!(m.name && m.name.length))
                            m.name = [];
                        m.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(r, r.uint32()));
                        break;
                    case 3:
                        m.identifierValue = r.string();
                        break;
                    case 4:
                        m.positiveIntValue = r.uint64();
                        break;
                    case 5:
                        m.negativeIntValue = r.int64();
                        break;
                    case 6:
                        m.doubleValue = r.double();
                        break;
                    case 7:
                        m.stringValue = r.bytes();
                        break;
                    case 8:
                        m.aggregateValue = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            UninterpretedOption.NamePart = (function() {

                function NamePart(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                NamePart.prototype.namePart = "";
                NamePart.prototype.isExtension = false;

                NamePart.create = function create(properties) {
                    return new NamePart(properties);
                };

                NamePart.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    w.uint32(10).string(m.namePart);
                    w.uint32(16).bool(m.isExtension);
                    return w;
                };

                NamePart.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.UninterpretedOption.NamePart();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.namePart = r.string();
                            break;
                        case 2:
                            m.isExtension = r.bool();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    if (!m.hasOwnProperty("namePart"))
                        throw $util.ProtocolError("missing required 'namePart'", { instance: m });
                    if (!m.hasOwnProperty("isExtension"))
                        throw $util.ProtocolError("missing required 'isExtension'", { instance: m });
                    return m;
                };

                return NamePart;
            })();

            return UninterpretedOption;
        })();

        protobuf.SourceCodeInfo = (function() {

            function SourceCodeInfo(p) {
                this.location = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            SourceCodeInfo.prototype.location = $util.emptyArray;

            SourceCodeInfo.create = function create(properties) {
                return new SourceCodeInfo(properties);
            };

            SourceCodeInfo.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.location != null && m.location.length) {
                    for (var i = 0; i < m.location.length; ++i)
                        $root.google.protobuf.SourceCodeInfo.Location.encode(m.location[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            SourceCodeInfo.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.SourceCodeInfo();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        if (!(m.location && m.location.length))
                            m.location = [];
                        m.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            SourceCodeInfo.Location = (function() {

                function Location(p) {
                    this.path = [];
                    this.span = [];
                    this.leadingDetachedComments = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Location.prototype.path = $util.emptyArray;
                Location.prototype.span = $util.emptyArray;
                Location.prototype.leadingComments = "";
                Location.prototype.trailingComments = "";
                Location.prototype.leadingDetachedComments = $util.emptyArray;

                Location.create = function create(properties) {
                    return new Location(properties);
                };

                Location.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.path != null && m.path.length) {
                        w.uint32(10).fork();
                        for (var i = 0; i < m.path.length; ++i)
                            w.int32(m.path[i]);
                        w.ldelim();
                    }
                    if (m.span != null && m.span.length) {
                        w.uint32(18).fork();
                        for (var i = 0; i < m.span.length; ++i)
                            w.int32(m.span[i]);
                        w.ldelim();
                    }
                    if (m.leadingComments != null && m.hasOwnProperty("leadingComments"))
                        w.uint32(26).string(m.leadingComments);
                    if (m.trailingComments != null && m.hasOwnProperty("trailingComments"))
                        w.uint32(34).string(m.trailingComments);
                    if (m.leadingDetachedComments != null && m.leadingDetachedComments.length) {
                        for (var i = 0; i < m.leadingDetachedComments.length; ++i)
                            w.uint32(50).string(m.leadingDetachedComments[i]);
                    }
                    return w;
                };

                Location.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.SourceCodeInfo.Location();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            if (!(m.path && m.path.length))
                                m.path = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.path.push(r.int32());
                            } else
                                m.path.push(r.int32());
                            break;
                        case 2:
                            if (!(m.span && m.span.length))
                                m.span = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.span.push(r.int32());
                            } else
                                m.span.push(r.int32());
                            break;
                        case 3:
                            m.leadingComments = r.string();
                            break;
                        case 4:
                            m.trailingComments = r.string();
                            break;
                        case 6:
                            if (!(m.leadingDetachedComments && m.leadingDetachedComments.length))
                                m.leadingDetachedComments = [];
                            m.leadingDetachedComments.push(r.string());
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Location;
            })();

            return SourceCodeInfo;
        })();

        protobuf.GeneratedCodeInfo = (function() {

            function GeneratedCodeInfo(p) {
                this.annotation = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            GeneratedCodeInfo.prototype.annotation = $util.emptyArray;

            GeneratedCodeInfo.create = function create(properties) {
                return new GeneratedCodeInfo(properties);
            };

            GeneratedCodeInfo.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.annotation != null && m.annotation.length) {
                    for (var i = 0; i < m.annotation.length; ++i)
                        $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(m.annotation[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            GeneratedCodeInfo.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.GeneratedCodeInfo();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        if (!(m.annotation && m.annotation.length))
                            m.annotation = [];
                        m.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            GeneratedCodeInfo.Annotation = (function() {

                function Annotation(p) {
                    this.path = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Annotation.prototype.path = $util.emptyArray;
                Annotation.prototype.sourceFile = "";
                Annotation.prototype.begin = 0;
                Annotation.prototype.end = 0;

                Annotation.create = function create(properties) {
                    return new Annotation(properties);
                };

                Annotation.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.path != null && m.path.length) {
                        w.uint32(10).fork();
                        for (var i = 0; i < m.path.length; ++i)
                            w.int32(m.path[i]);
                        w.ldelim();
                    }
                    if (m.sourceFile != null && m.hasOwnProperty("sourceFile"))
                        w.uint32(18).string(m.sourceFile);
                    if (m.begin != null && m.hasOwnProperty("begin"))
                        w.uint32(24).int32(m.begin);
                    if (m.end != null && m.hasOwnProperty("end"))
                        w.uint32(32).int32(m.end);
                    return w;
                };

                Annotation.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            if (!(m.path && m.path.length))
                                m.path = [];
                            if ((t & 7) === 2) {
                                var c2 = r.uint32() + r.pos;
                                while (r.pos < c2)
                                    m.path.push(r.int32());
                            } else
                                m.path.push(r.int32());
                            break;
                        case 2:
                            m.sourceFile = r.string();
                            break;
                        case 3:
                            m.begin = r.int32();
                            break;
                        case 4:
                            m.end = r.int32();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                return Annotation;
            })();

            return GeneratedCodeInfo;
        })();

        protobuf.Timestamp = (function() {

            function Timestamp(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            Timestamp.prototype.nanos = 0;

            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            Timestamp.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.seconds != null && m.hasOwnProperty("seconds"))
                    w.uint32(8).int64(m.seconds);
                if (m.nanos != null && m.hasOwnProperty("nanos"))
                    w.uint32(16).int32(m.nanos);
                return w;
            };

            Timestamp.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.Timestamp();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.seconds = r.int64();
                        break;
                    case 2:
                        m.nanos = r.int32();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

export const type = $root.type = (() => {

    const type = {};

    type.media_content_display_status = (function() {

        const media_content_display_status = {};

        media_content_display_status.MediaContentDisplayStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "non_public"] = 0;
            values[valuesById[1] = "public"] = 1;
            return values;
        })();

        return media_content_display_status;
    })();

    type.modal_type = (function() {

        const modal_type = {};

        modal_type.ModalType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "athlete_scheme"] = 0;
            return values;
        })();

        return modal_type;
    })();

    type.payment_status = (function() {

        const payment_status = {};

        payment_status.PaymentStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "init"] = 0;
            values[valuesById[1] = "pending"] = 1;
            values[valuesById[2] = "completed"] = 2;
            values[valuesById[3] = "canceled"] = 3;
            values[valuesById[4] = "provider_error"] = 4;
            return values;
        })();

        return payment_status;
    })();

    type.player_contract_status = (function() {

        const player_contract_status = {};

        player_contract_status.PlayerContractStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "registered"] = 0;
            values[valuesById[1] = "active"] = 1;
            values[valuesById[2] = "terminated"] = 2;
            values[valuesById[3] = "suspended"] = 3;
            return values;
        })();

        return player_contract_status;
    })();

    type.player_public_status = (function() {

        const player_public_status = {};

        player_public_status.PlayerPublicStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "non_public"] = 0;
            values[valuesById[1] = "public"] = 1;
            values[valuesById[2] = "retired"] = 2;
            values[valuesById[3] = "temporary_public"] = 3;
            return values;
        })();

        return player_public_status;
    })();

    type.point_history_type = (function() {

        const point_history_type = {};

        point_history_type.PointHistoryType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "payment"] = 0;
            values[valuesById[1] = "cheer"] = 1;
            values[valuesById[2] = "expire"] = 2;
            values[valuesById[3] = "compensation"] = 3;
            return values;
        })();

        return point_history_type;
    })();

    type.provider_contract_status = (function() {

        const provider_contract_status = {};

        provider_contract_status.ProviderContractStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "registered"] = 0;
            values[valuesById[1] = "active"] = 1;
            values[valuesById[2] = "terminated"] = 2;
            values[valuesById[3] = "suspended"] = 3;
            return values;
        })();

        return provider_contract_status;
    })();

    type.rima_pay = (function() {

        const rima_pay = {};

        rima_pay.charge_status = (function() {

            const charge_status = {};

            charge_status.ChargeStatus = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "succeeded"] = 0;
                values[valuesById[1] = "processing"] = 1;
                values[valuesById[2] = "failed"] = 2;
                values[valuesById[3] = "canceled"] = 3;
                return values;
            })();

            return charge_status;
        })();

        rima_pay.event_type = (function() {

            const event_type = {};

            event_type.EventType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "charge_succeeded"] = 0;
                values[valuesById[1] = "charge_failed"] = 1;
                values[valuesById[2] = "charge_pending"] = 2;
                values[valuesById[3] = "charge_captured"] = 3;
                values[valuesById[4] = "charge_refunded"] = 4;
                values[valuesById[5] = "charge_updated"] = 5;
                return values;
            })();

            return event_type;
        })();

        return rima_pay;
    })();

    type.user_image_type = (function() {

        const user_image_type = {};

        user_image_type.UserImageType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "thumbnail"] = 0;
            values[valuesById[1] = "coverimage"] = 1;
            return values;
        })();

        return user_image_type;
    })();

    type.user_type = (function() {

        const user_type = {};

        user_type.UserType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "fan"] = 0;
            values[valuesById[1] = "player"] = 1;
            return values;
        })();

        return user_type;
    })();

    type.video_comment_type = (function() {

        const video_comment_type = {};

        video_comment_type.VideoCommentType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "text"] = 0;
            values[valuesById[1] = "official_text"] = 1;
            return values;
        })();

        return video_comment_type;
    })();

    return type;
})();

export { $root as default };
