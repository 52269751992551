export enum LocalStorageKey {
  loggedin = 'loggedin'
}

/**
 * ローカルストレージに対する操作を行うラッパークラス。
 */
export class LocalStorageUtil {
  /**
   * ローカルストレージに値を保存する。
   *
   * @param key ローカルストレージのキー
   * @param value 保存する値
   */
  static set<T>(key: LocalStorageKey, value: T): void {
    if (localStorage) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  /**
   * ローカルストレージに保存されている値を取得する。
   * 値が存在しなければ `null` を返す。
   *
   * @param key ローカルストレージのキー
   */
  static get<T>(key: LocalStorageKey): T | null {
    if (localStorage) {
      const value = localStorage.getItem(key);

      if (typeof value === 'string') {
        return JSON.parse(value);
      }
    }

    return null;
  }

  /**
   * ローカルストレージの値を削除する。
   *
   * @param key 削除対象のローカルストレージのキー
   */
  static remove(key: LocalStorageKey): void {
    if (localStorage) {
      localStorage.removeItem(key);
    }
  }
}
