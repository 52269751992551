import { differenceInSeconds, addSeconds } from 'date-fns';

/**
 * 現在時刻を取得するためのシングルトンクラス。
 * 時刻偽装をする際にも利用する。
 */
export class CurrentTime {
  private static currentTimeInstance: CurrentTime;

  /** サーバーから取得した日付時刻とブラウザから取得した日付時刻の差分(秒) */
  private timeDiffInSeconds = 0;

  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  /**
   * インスタンスを取得する。
   */
  static get instance(): CurrentTime {
    if (!this.currentTimeInstance) {
      this.currentTimeInstance = new CurrentTime();
    }

    return this.currentTimeInstance;
  }

  /**
   * 現在時刻をセットする。
   * 以降に `getCurrentDate` で返される日付時刻は、ここでセットした値に基づいて決定される。
   *
   * @param time 現在時刻
   */
  setCurrentTime(time: Date): void {
    const browserBaseDate = new Date();
    this.timeDiffInSeconds = differenceInSeconds(time, browserBaseDate);
  }

  /**
   * 現在時刻を返す。
   * `setCurrentTime` によって時刻がセットされている場合、その値に基づいた時刻を返す。
   */
  getCurrentDate(): Date {
    const browserCurrentDate = new Date();
    return addSeconds(browserCurrentDate, this.timeDiffInSeconds);
  }
}
