









import { Vue, Component } from 'vue-property-decorator';
import UlFooter from '~/components/common/ul-footer.vue';

@Component({
  components: { UlFooter }
})
export default class LoginLayout extends Vue {}
