import { format } from 'date-fns';

/**
 * 第2引数で日付表示フォーマットを指定出来る日付フォーマットフィルター
 * @param date Date
 * @param formatString 'yyyy-MM-dd' などの日付フォーマット
 */
export default (date: Date, formatString: string): string => {
  return `${format(date, formatString)}`;
};
