const intlNumberFormatJa = new Intl.NumberFormat('ja-JP', { useGrouping: true });
/**
 * 金額の値を文字列としてフォーマットするfilter。
 */
export default (amount: number): string => {
  if (typeof amount === 'number') {
    const sign = amount >= 0 ? '' : '-';
    return sign + intlNumberFormatJa.format(Math.abs(amount));
  } else {
    return '';
  }
};
