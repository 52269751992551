/**
 * 小数点２桁で丸めて表示するフィルタ。
 * 切り捨てた後にpaddingする。
 **/
const intlNumberFormatJa = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });

export default (amount: number): string => {
  if (typeof amount === 'number') {
    return intlNumberFormatJa.format(Math.floor(amount * 100) / 100);
  } else {
    return '';
  }
};
