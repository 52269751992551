import { Context } from '@nuxt/types';
import { getModule } from 'vuex-module-decorators';
import Provider from '~/store/provider';

export default function ({ app, store, redirect }: Context): void {
  const providerModule = getModule(Provider, store);

  if (providerModule.loggedin === null) {
    providerModule.INIT();
  }

  if (!providerModule.loggedin) {
    redirect('/login', { redirect_to: app.router!.currentRoute.fullPath });
  }
}
