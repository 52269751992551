

























import { Vue, Component } from 'vue-property-decorator';

import UlHeader from '~/components/common/ul-header.vue';
import UlFooter from '~/components/common/ul-footer.vue';

@Component({
  components: { UlHeader, UlFooter },
  head: {
    bodyAttrs: {
      class: 'has-navbar-fixed-top'
    }
  }
})
export default class extends Vue {
  items = [
    {
      title: 'ダッシュボード',
      icon: 'view-dashboard',
      to: { name: 'index' }
    },
    {
      title: '埋め込みタグ',
      icon: 'xml',
      to: { name: 'tag' }
    },
    {
      title: 'お支払い',
      icon: 'cash',
      to: { name: 'payment' }
    },
    {
      title: 'お知らせ',
      icon: 'bell',
      to: { name: 'news' }
    }
  ];
}
