import axios, { AxiosPromise } from 'axios';
import ProtoUtil from './proto-util';
import { ProtobufResponse, ProtobufRequest } from '~/@types/protobuf';
import { provider_api as api } from '~/models/proto/compiled';
import { HttpStatus } from '~/models/app';
import { StatusCode } from '~/models/proto';

export class ApiClient {
  /**
   * API サーバーへとリクエストを送る。
   * この関数はProtocol Buffersによるたバイナリのエンコード/デコード処理をラップしている。
   *
   * @param path APIのパス。
   * @param params APIのリクエストパラメータ。
   * @param response レスポンスクラス。Protocol Buffersの定義に従い、レスポンスのデコード処理を行うために使用される。
   * @param request リクエストクラス。Protocol Buffersの定義に従い、リクエストパラメータのエンコード処理を行うために使用される。
   */
  static post<Res, IReq, Req extends IReq>(path: string, params: IReq, response: ProtobufResponse, request: ProtobufRequest<IReq, Req>): AxiosPromise<Res> {
    // リクエストパラメータをバッファに変換する
    const encodedParam = request.encode(request.create(params)).finish().slice();

    const headers = {
      'content-type': 'application/protobuf'
    };

    const promise = axios
      .post(`${process.env.apiBaseUrl}/${path}`, encodedParam, {
        responseType: 'arraybuffer',
        headers,
        validateStatus: (status: number) => {
          // status codeが200以外の場合はrejectする
          return status === HttpStatus.SUCCESS;
        },
        withCredentials: true
      })
      .then((res) => {
        const data = new Uint8Array(res.data);
        res.data = ProtoUtil.flatternObject(response.decode(data));
        if (process.env.apiLog === 'true') {
          // eslint-disable-next-line no-console
          console.info('[Response]', res.data);
        }

        if (res.data.status !== undefined && res.data.status.code === StatusCode.MAINTENANCE) {
          // eslint-disable-next-line no-throw-literal
          throw { response: res };
        }

        return res;
      });

    return promise;
  }

  static login(params: api.provider.login.Request): AxiosPromise<api.provider.login.Response> {
    return ApiClient.post('provider/login', params, api.provider.login.Response, api.provider.login.Request);
  }

  static logout(params: api.provider.logout.Request = {}): AxiosPromise<api.provider.logout.Response> {
    return ApiClient.post('provider/logout', params, api.provider.logout.Response, api.provider.logout.Request);
  }

  static changePassword(params: api.provider.change_password.Request): AxiosPromise<api.provider.change_password.Response> {
    return ApiClient.post('provider/change_password', params, api.provider.change_password.Response, api.provider.change_password.Request);
  }

  static now(params: api.provider.now.Request): AxiosPromise<api.provider.now.Response> {
    return ApiClient.post('provider/now', params, api.provider.now.Response, api.provider.now.Request);
  }

  static reportCheerByDateFetch(params: api.report.cheer_by_date.Request): AxiosPromise<api.report.cheer_by_date.Response> {
    return ApiClient.post('report/cheer_by_date', params, api.report.cheer_by_date.Response, api.report.cheer_by_date.Request);
  }

  static reportCheerByUrlFetch(params: api.report.cheer_by_url.Request): AxiosPromise<api.report.cheer_by_url.Response> {
    return ApiClient.post('report/cheer_by_url', params, api.report.cheer_by_url.Response, api.report.cheer_by_url.Request);
  }

  static reportCheerByAthleteFetch(params: api.report.cheer_by_athlete.Request): AxiosPromise<api.report.cheer_by_athlete.Response> {
    return ApiClient.post('report/cheer_by_athlete', params, api.report.cheer_by_athlete.Response, api.report.cheer_by_athlete.Request);
  }

  static paymentHistoriesFetch(param: api.payment.histories.Request): AxiosPromise<api.payment.histories.Response> {
    return ApiClient.post('payment/histories', param, api.payment.histories.Response, api.payment.histories.Request);
  }

  static playerTagFetch(param: api.tag.players.Request): AxiosPromise<api.tag.players.Response> {
    return ApiClient.post('tag/players', param, api.tag.players.Response, api.tag.players.Request);
  }

  static fetchProfile(params: api.profile.fetch.Request = {}): AxiosPromise<api.profile.fetch.Response> {
    return ApiClient.post('profile/fetch', params, api.profile.fetch.Response, api.profile.fetch.Request);
  }
}
