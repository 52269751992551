import Long from 'long';
import { AxiosResponse } from 'axios';
import { Mutation, VuexModule, Module, Action } from 'vuex-module-decorators';
import { LocalStorageUtil, LocalStorageKey } from '@/services/local-storage-util';
import { provider_api as api } from '~/models/proto/compiled';
import { ApiClient } from '~/services/api-client';
import { StatusCode } from '~/models/proto';

export interface ProviderProfile {
  /** プロバイダID */
  id: Long;

  /** email */
  email: string;
}

export interface ProviderState {
  /** ログイン済みか否か。初期化されていない場合はundefinedとなる */
  loggedin: boolean | null;
  /** ログインしているプロバイダの情報 */
  providerProfile: ProviderProfile | null;
  /** ログインに絡むエラーが発生しているか */
  hasLoginError: boolean;
}
@Module({ name: 'provider', namespaced: true, stateFactory: true })
export default class Provider extends VuexModule implements ProviderState {
  loggedin: boolean | null = null;
  hasLoginError: boolean = false;
  providerProfile: ProviderProfile | null = null;

  // mutation
  @Mutation
  INIT(): void {
    // Local Storageに格納された値をもとにしてログイン状態を更新
    const loggedin = LocalStorageUtil.get<boolean>(LocalStorageKey.loggedin);
    this.loggedin = loggedin !== null ? loggedin : false;
  }

  @Mutation
  LOGIN(): void {
    this.loggedin = true;
    this.hasLoginError = false;
    LocalStorageUtil.set(LocalStorageKey.loggedin, true);
  }

  @Mutation
  LOGOUT(): void {
    this.loggedin = false;
    LocalStorageUtil.set(LocalStorageKey.loggedin, false);
  }

  @Mutation
  SET_PROVIDER_PROFILE(profile: ProviderProfile): void {
    this.providerProfile = profile;
  }

  @Mutation
  CLEAR_PROVIDER_PROFILE(): void {
    this.providerProfile = null;
  }

  @Mutation
  SET_HAS_LOGIN_ERROR(): void {
    this.hasLoginError = true;
  }

  @Action({ rawError: true })
  async providerLogin(params: api.provider.login.Request): Promise<AxiosResponse<api.provider.login.Response>> {
    return await ApiClient.login(params);
  }

  @Action({ rawError: true })
  async fetchProvider(): Promise<AxiosResponse<api.profile.fetch.Response>> {
    const res = await ApiClient.fetchProfile();
    if (res.data.status?.code === StatusCode.SUCCESS) {
      const providerProfile: ProviderProfile = { id: res.data.data!.providerId!, email: res.data.data!.email! };
      this.SET_PROVIDER_PROFILE(providerProfile);
    }
    return res;
  }

  get providerId(): Long | null {
    return this.providerProfile?.id ?? null;
  }

  get email(): string | null {
    return this.providerProfile?.email ?? null;
  }
}
