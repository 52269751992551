/**
 * システムに関する環境値
 */
export interface AppEnv {
  /** 開発環境か否か */
  dev: 'true' | 'false';
  /** 環境の値 */
  NODE_ENV: string;
  /** APIサーバーのURL */
  apiBaseUrl: string;
  /** サービスのベースURL */
  baseUrl: string;
  /** アセット取得元のURL */
  assetBaseUrl: string;
  /** Rollbarアクセストークン (使用しない場合はセットしない) */
  rollbarAccessToken?: string;
  /** API通信の結果を表示するか */
  apiLog: 'true' | 'false';
  /** 時間偽装を有効にするか。これがtrueであれば、サーバーから取得した時刻に基づいてで現在時刻を決定する */
  useTimeLeap: 'true' | 'false';
  /** Unlim WebクライアントのベースURL */
  webClientBaseUrl: string;
  /** ContentfulのスペースID */
  contentfulSpaceID: string;
  /** Contentfulのアクセストークン */
  contentfulAccessToken: string;
  /** ContentfulのAPIホスト */
  contentfulHost: string;
  /** ContentfulのNews記事のコンテンツ名 */
  contentfulNewsContentType: string;
}

export enum HttpStatus {
  SUCCESS = 200,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500
}
