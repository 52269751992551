import { getModule } from 'vuex-module-decorators';
import { Store } from 'vuex';
import Provider from '~/store/provider';

/**
 * nuxt-client-init-module から Vuex にアクセスする際に使用
 */
export class StoreAccesser {
  static initialized: boolean = false;
  static providerStore: Provider | null = null;

  static init(store: Store<any>): void {
    if (!this.initialized) {
      this.providerStore = getModule(Provider, store);
      this.initialized = true;
    }
  }
}
