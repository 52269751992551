import Long from 'long';

/**
 * `low` と `high` のプロパティを持つ `Long` に変換可能な型
 */
interface LongLike {
  low: number;
  high: number;
}

/**
 * Longのユーティリティクラス。
 */
export class LongUtil {
  /**
   * Long型と同等のプロパティを持つオブジェクトかどうかをチェックするType Guard。
   * Protobufによって生成されるオブジェクトは、Longオブジェクトそのものではなく、 `high` と `low` の値を持つオブジェクトとして生成するようなので、
   * このようなチェック関数を設けている。
   *
   * @param value チェック対象の値
   */
  static isLongLikeObject(value: any): value is LongLike {
    // `high` と `low` を持っているかどうかをチェック
    return typeof value === 'object' && value !== null && typeof value.high === 'number' && typeof value.low === 'number';
  }

  /**
   * LongっぽいオブジェクトをLongにする。
   *
   * @param value Longっぽいオブジェクト
   */
  static toLongFromLongLike(value: LongLike): Long {
    return new Long(value.low, value.high);
  }
}
