import Vue from 'vue';
import dateRangeFilter from '~/filters/format-date-range';
import formatNumber from '~/filters/format-number';
import formatFloat from '~/filters/format-float';
import formatFloatPercent from '~/filters/format-float-percent';
import formatDateFilter from '~/filters/format-date';

Vue.filter('formatDateRange', dateRangeFilter);
Vue.filter('formatNumber', formatNumber);
Vue.filter('formatFloat', formatFloat);
Vue.filter('formatFloatPercent', formatFloatPercent);
Vue.filter('formatDate', formatDateFilter);
