












import { setTimeout, clearTimeout } from 'timers';
import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Loading from '~/store/loading';

@Component({})
export default class UlLoading extends Vue {
  readonly INDICATOR_DELAY = 500;
  loading = false;
  indicator = false;
  timer: NodeJS.Timer | null = null;

  created(): void {
    this.$store.watch(
      (state) => state.loading.loading,
      () => {
        const loadingModule = getModule(Loading, this.$store);
        if (loadingModule.loading) {
          this.$nuxt.$loading.start();
        } else {
          this.$nuxt.$loading.finish();
        }
      }
    );
  }

  start(): void {
    this.loading = true;
    this.indicator = false;
    this.timer = setTimeout(() => {
      this.indicator = true;
    }, this.INDICATOR_DELAY);
  }

  finish(): void {
    this.loading = false;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }
}
