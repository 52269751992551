import { Mutation, Action, VuexModule, Module } from 'vuex-module-decorators';
import * as Contentful from 'contentful';
import { NewsEntry } from '~/models/news';

const config = {
  space: process.env.contentfulSpaceID!,
  accessToken: process.env.contentfulAccessToken!,
  host: process.env.contentfulHost!
};

const TOP_ENTRY_COUNT = 5;

/**
 * 記事リストを保持するモジュール
 */
@Module({ name: 'news', namespaced: true, stateFactory: true })
export default class News extends VuexModule {
  /** 読み込んだ記事リスト */
  entries: NewsEntry[] = [];
  entriesBySlug: { [key: string]: NewsEntry } = {};

  get topEntries(): NewsEntry[] {
    return this.entries.slice(0, TOP_ENTRY_COUNT);
  }

  @Mutation
  clear(): void {
    this.entries = [];
  }

  @Mutation
  setEntries({ entries }: { entries: NewsEntry[] }): void {
    if (entries.length === 0) return;

    this.entries = entries;
    entries.forEach((entry) => (this.entriesBySlug[entry.fields.slug] = entry));
  }

  /**
   * メディア情報をサーバーから取得する
   */
  @Action({ commit: 'setEntries', rawError: true })
  async fetchNews(): Promise<{ entries: NewsEntry[] }> {
    if (this.entries.length > 0) return { entries: [] };

    const entries = await Contentful.createClient(config).getEntries({
      content_type: process.env.contentfulNewsContentType,
      order: '-fields.releaseDate'
    });
    return { entries: entries.items as NewsEntry[] };
  }
}
