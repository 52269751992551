/** toastによってメッセージを通知する */
import { BNoticeComponent } from 'buefy/types/components';
import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import { getModule } from 'vuex-module-decorators';
import { AxiosResponse } from 'axios';
import Provider from '~/store/provider';
import { HttpStatus } from '~/models/app';
import { StatusCode, IStatus } from '~/models/proto';
import Loading from '~/store/loading';

interface Stores {
  /** Vuex Providerモジュール */
  provider: Provider;
  /** Vuex Loadingモジュール */
  loading: Loading;
}

/**
 * 通信およびvuexについて定義した層。すべてのコンポーネントが継承する可能性がある。
 *
 * 注) 基本的に全ての page component はこのmixinを継承する。
 * つまり、このファイルが肥大化すると、各ページのダウンロードサイズが増えることを意味する。
 * そのため、このファイル、およびimportしているファイルサイズが大きくなるようであれば、適切な分割を検討すること。
 */
@Mixin
export default class BaseMixin extends Vue {
  toast = {
    /** 成功を通知する */
    success: (message: string): BNoticeComponent => this.$buefy.toast.open({ message, type: 'is-success', position: 'is-bottom' }),
    /** 失敗を通知する */
    error: (message: string): BNoticeComponent => this.$buefy.toast.open({ message, type: 'is-danger', queue: false, duration: 2750, position: 'is-bottom' })
  };

  handleAxiosResponseError(response: AxiosResponse | undefined): void {
    if (response && typeof response.status === 'number') {
      switch (response.status) {
        case HttpStatus.SUCCESS: {
          const status = response.data.status as IStatus;

          switch (status.code) {
            case StatusCode.MAINTENANCE:
              this.toast.error(status.message!);
              break;
            default:
              throw new Error(`Unexpected status code: ${status.code}`);
          }
          break;
        }
        case HttpStatus.FORBIDDEN:
          // 認証エラーの場合
          this.stores.provider.LOGOUT();
          if (!this.stores.provider.hasLoginError) {
            this.stores.provider.SET_HAS_LOGIN_ERROR();
            this.toast.error('ログインしてください');
          }
          this.$router.push('/login');
          break;
        case HttpStatus.INTERNAL_SERVER_ERROR:
          this.toast.error('サーバーでエラーが発生しました');
          break;
        default:
          // 上記以外のステータスコードは想定されていないステータスコードなので、メッセージを表示した上で
          // Errorをthrowして、Rollbarへと通知する
          this.toast.error('エラーが発生しました');
          throw new Error(`Server sent a response with status code ${response.status}`);
      }
    } else {
      // responseかresponse.statusがundefinedである場合には、ネットワークエラーとみなす
      // See: https://github.com/axios/axios/issues/383#issuecomment-436506793
      this.toast.error('通信に失敗しました。ネットワークの状態をご確認ください');
    }
  }

  /**
   * ログイン済みか否か
   */
  get isLoggedin(): boolean {
    // ログイン状態が初期化されていなければ初期化
    const providerModule = getModule(Provider, this.$store);

    if (providerModule.loggedin === null) {
      providerModule.INIT();
    }

    return providerModule.loggedin || false;
  }

  /**
   * Vuex storeの各種モジュール群
   */
  get stores(): Stores {
    return {
      provider: this._provider,
      loading: this._loading
    };
  }

  private get _provider(): Provider {
    return getModule(Provider, this.$store);
  }

  private get _loading(): Loading {
    return getModule(Loading, this.$store);
  }
}
