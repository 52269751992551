/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { provider_api as api } from './compiled';
export import StatusCode = api.common.StatusCode;
export import IStatus = api.common.IStatus;

export type IPaymentResponse = api.payment.histories.Response;
export type IPaymentData = api.payment.histories.Response.IData;
export type IPaymentHistory = api.payment.histories.Response.IPaymentHistory;
export type IPlayer = api.tag.players.Response.Data.IPlayer
