import { Mutation, VuexModule, Module } from 'vuex-module-decorators';

/**
 * ローディング状態を管理するVuexモジュール。
 */
@Module({ name: 'loading', namespaced: true, stateFactory: true })
export default class Loading extends VuexModule {
  /** ローディング中か否か */
  loading = false;

  /**
   * ローディングアイコンの表示、および画面マスク処理を行う。
   */
  @Mutation
  START(): void {
    this.loading = true;
  }

  /**
   * ローディングアイコンの非表示にし、画面マスク処理を終了させる。
   */
  @Mutation
  FINISH(): void {
    this.loading = false;
  }
}
