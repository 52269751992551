




















































import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { StatusCode } from '~/models/proto';
import { ApiClient } from '~/services/api-client';
import BaseMixin from '~/mixins/base-mixin';
import Provider from '~/store/provider';

@Component
export default class UlHeader extends BaseMixin {
  /** ログアウト確認のモーダル表示  */
  showLogoutModal: boolean = false;

  private providerModule = getModule(Provider, this.$store);

  cancel(): void {
    this.showLogoutModal = false;
  }

  logoutClicked(): void {
    this.showLogoutModal = true;
  }

  goToPasswordPage(): void {
    this.$router.push('/settings/password');
  }

  async logoutSubmit(): Promise<void> {
    let code!: StatusCode;
    try {
      const res = await ApiClient.logout({});
      code = res.data.status!.code!;
    } catch (e) {
      this.handleAxiosResponseError(e);
      return;
    }

    switch (code) {
      case StatusCode.SUCCESS:
        this.stores.provider.LOGOUT();
        this.stores.provider.CLEAR_PROVIDER_PROFILE();
        this.$router.push('/login');
        break;
      default:
        this.toast.error('エラーが発生しました。');
        throw new Error(`Unexpected status code: ${code}`);
    }
  }

  get email(): string {
    return this.providerModule.email ?? '';
  }
}
