import { LongUtil } from './long-util';
import { google } from '~/models/proto/compiled';
import { isObject } from '~/misc/type-guards';

/**
 * Protocol Bufferにより生成されたオブジェクトを扱うためのユーティリティクラス。
 */
export default class ProtoUtil {
  /**
   * オブジェクトをプロトタイプの値も含めてコピーする
   * これは、ProtobufjsではEnumの値が0となる場合に、プロトタイプだけが値を持ち、オブジェクトそのものは値を持っていないという現象が発生するため、その対策としてこの関数を用意している。
   */
  static flatternObject<T extends object>(obj: T): T {
    if (typeof obj !== 'object') {
      return obj;
    }

    const newObj: any = {};
    const keys = Object.keys(obj).concat(Object.keys(Object.getPrototypeOf(obj))) as (keyof T)[];

    for (const key of keys) {
      const value = obj[key];
      const type = typeof value;

      if (type !== 'function' && type !== 'undefined' && type !== null) {
        if (Array.isArray(value)) {
          const newArray: any[] = [];
          value.forEach((i: any) => newArray.push(this.flatternObject(i)));
          newObj[key] = newArray;
        } else if (isObject(value) && value !== null) {
          newObj[key] = this.flatternObject(value);
        } else {
          newObj[key] = value;
        }
      }
    }

    return newObj;
  }

  /**
   * ITimestamp型の時間情報をDateオブジェクトに変換する。
   * なお、 `timeStamp` の値が `null` や `undefined` になっている場合は、単に `null` を返す。
   *
   * @param timeStamp タイムスタンプ
   */
  static convertToDateFromTimeStamp(timeStamp: google.protobuf.ITimestamp | null | undefined): Date | null {
    if (this.ensure(timeStamp) && this.ensure(timeStamp.seconds)) {
      const seconds = LongUtil.toLongFromLongLike(timeStamp.seconds).toNumber();
      return new Date(seconds * 1000);
    } else {
      return null;
    }
  }

  static isString(value: any): value is string {
    return typeof value === 'string' && value !== undefined && value !== null;
  }

  private static ensure<T>(value: T | null | undefined): value is T {
    return value !== undefined && value !== null;
  }
}
