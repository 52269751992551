import { Store } from 'vuex';
import { StoreAccesser } from './store-accesser';
import { CurrentTime } from '~/services/current-time';
import { ApiClient } from '~/services/api-client';
import protobufInit from '~/services/protobuf-init';

const inializer = (store: Store<any>): void => StoreAccesser.init(store);
export const plugins = [inializer];

export const actions = {
  nuxtServerInit(): void {},

  async nuxtClientInit(): Promise<void> {
    // この関数は、本来Storeの初期化処理に使用されるものなのであるが、
    // Protobufの初期化処理を確実に行うことができる適当な場所が他に見当たらないので、
    // 以下のProtobuf初期化処理を置いている
    protobufInit();

    if (process.env.useTimeLeap === 'true') {
      // 現在時刻をセットする(偽装する)
      console.info('Current time is set 😇');
      const res = await ApiClient.now({});
      CurrentTime.instance.setCurrentTime(new Date(res.data.data!.currentTime!));
    }

    // ログイン中であれば、プロバイダ(ユーザー)の情報をサーバーから取得する
    await initProviderProfile();
  }
};

async function initProviderProfile(): Promise<void> {
  if (StoreAccesser.providerStore === null) {
    return;
  }

  // ローカルストレージの値を元に、ログイン済みかどうかのを示す状態を初期化
  StoreAccesser.providerStore.INIT();

  if (StoreAccesser.providerStore.loggedin) {
    try {
      await StoreAccesser.providerStore.fetchProvider();
    } catch (e: unknown) {
      console.error(e);
    }
  }
}
