/**
 * 小数点２桁パーセントで丸めて表示するフィルタ。 返す文字列に`%`を含む。
 * 切り捨てた後にpaddingする。
 */
export default (amount: number): string => {
  if (typeof amount === 'number') {
    return `${(Math.floor(amount * 100 * 100) / 100).toFixed(2)}%`;
  } else {
    return '';
  }
};
